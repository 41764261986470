export enum DIFFICULTIES {
  BEGINNER = 'beginner',
  ADVANCED = 'advanced',
  INTERMEDIATE = 'intermediate',
}

export enum CATEGORIES {
  BLOCKCHAIN = 'blockchain & smart contracts',
  WEBDEV = 'web development',
  DATASCIENCE = 'data science',
}

export enum RESULT_STATUSES {
  INPROGRESS = 'inprogress',
  FINISHED = 'finished',
}

export enum ECourseSpaceVisibility {
  Private = 'private',
  Visible = 'visible',
  Invisible = 'invisible',
}
