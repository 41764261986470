import { FastAverageColor } from 'fast-average-color';
import { RefObject, useEffect, useState } from 'react';
import { AverageColorOprionsInterface } from './useAverageColorFromImageUrl';

export const useAverageColor = (dom: RefObject<HTMLImageElement> | null, options: AverageColorOprionsInterface = {}) => {
  const [averageColor, setAverageColor] = useState('rgba(100, 100, 100, 0.1)');
  useEffect(() => {
    const calculateAverageColor = () => {
      if (dom?.current) {
        const fac = new FastAverageColor();
        fac
          .getColorAsync(dom.current)
          .then((color) => {
            setAverageColor(color.rgba.replace(/(rgba\(\d+,\d+,\d+,).*\)/, `$1 ${options && options.opacity ? options?.opacity : 1})`));
          })
          .catch((error) => {
            console.error('Error getting average color:', error);
          });
        fac.destroy();
      }
    };

    const img = dom?.current;
    if (img) {
      if (img.complete) {
        calculateAverageColor();
      } else {
        img.addEventListener('load', calculateAverageColor);
        return () => {
          img.removeEventListener('load', calculateAverageColor);
        };
      }
    }
  }, [dom, Object.keys(options).join('')]);

  return averageColor;
};
