import { useSelector } from 'react-redux';
import { useGetIsPremium } from './useGetIsPremium';
import { getUserCooldowns } from 'store';
import { useEffect, useState } from 'react';
import dayjs from 'dayjs';

export const useCheckIsCooldown = (type: 'meditations' | 'books' | 'battles') => {
  const [isCooldown, setIsCooldown] = useState(false);
  const { isPremium } = useGetIsPremium();
  const { cooldownEnds } = useSelector(getUserCooldowns)[type];

  useEffect(() => {
    setIsCooldown(dayjs.utc(cooldownEnds).isAfter(dayjs.utc()));
  }, [cooldownEnds]);

  return isPremium ? false : isCooldown;
};
