import { ECourseSpaceVisibility, EGroupTag as EGroupTagRaw } from 'app/constants';
import { ICourseFilteringGroup } from 'components/pages/study/courses/hooks';
import { useMemo } from 'react';
import { useGetIsAuth, useGetSettings } from './useGetStoreGeneralData';
import { useGetUserData } from './useGetUserData';

export const useGetCourseGroups = (devStatus: boolean | 'all' = false) => {
  const isAuth = useGetIsAuth();
  const { displayedName } = useGetSettings();
  const { favouriteCourses: userFavs = [] } = useGetUserData();

  const {
    favouritesKey,
    EGroupTag,
    partnerOriginals,
    tags,
    groupsNames: groupsNativeNames,
    groupsFilterNames,
    favouriteCourses,
  } = useMemo(() => {
    const favouritesKey = 'favourites';

    const EGroupTag = { ...EGroupTagRaw };

    const partnerOriginals = `${displayedName} ${EGroupTag.Originals}`;

    const tags: Record<string, ICourseFilteringGroup> = {
      [EGroupTag.AIAutomation]: {
        label: EGroupTag.AIAutomation,
        native: EGroupTag.AIAutomation,
        priority: 2,
      },
      [EGroupTag.Originals]: {
        label: partnerOriginals,
        native: EGroupTag.Originals,
        priority: 3,
      },
      [EGroupTag.BitcoinCryptocurrency]: {
        label: EGroupTag.BitcoinCryptocurrency,
        native: EGroupTag.BitcoinCryptocurrency,
        priority: 2,
      },
      [EGroupTag.BlockchainProgramming]: {
        label: EGroupTag.BlockchainProgramming,
        native: EGroupTag.BlockchainProgramming,
        priority: 2,
      },
      [EGroupTag.DataScience]: {
        label: EGroupTag.DataScience,
        native: EGroupTag.DataScience,
        priority: 2,
      },
      [EGroupTag.DecentralisedFinance]: {
        label: EGroupTag.DecentralisedFinance,
        native: EGroupTag.DecentralisedFinance,
        priority: 2,
      },
      [EGroupTag.Gaming]: {
        label: EGroupTag.Gaming,
        native: EGroupTag.Gaming,
        priority: 2,
      },
      [EGroupTag.Programming]: {
        label: `${EGroupTag.Programming} courses`,
        native: EGroupTag.Programming,
        priority: 2,
      },
      [EGroupTag.SocialMedia]: {
        label: `${EGroupTag.SocialMedia} courses`,
        native: EGroupTag.SocialMedia,
        priority: 2,
      },
      [EGroupTag.Web3]: {
        label: EGroupTag.Web3,
        native: EGroupTag.Web3,
        priority: 2,
      },
      [EGroupTag.WebDevelopment]: {
        label: EGroupTag.WebDevelopment,
        native: EGroupTag.WebDevelopment,
        priority: 2,
      },
    };

    const getPriority = () => {
      if (devStatus === 'all') {
        return userFavs;
      }
      if (devStatus) {
        return userFavs.filter((i) => i.isInDevelopment || i.spaceVisibility === ECourseSpaceVisibility.Invisible);
      }
      return userFavs.filter((i) => !i.isInDevelopment || i.spaceVisibility === ECourseSpaceVisibility.Visible);
    };

    const favouriteCourses = getPriority();

    if (isAuth) {
      tags[favouritesKey] = {
        label: favouritesKey,
        native: favouritesKey,
        priority: favouriteCourses.length > 0 ? 0 : 3,
      };
    }

    const values = Object.values(tags);
    const groupsNames = Object.values(values)
      .sort((a, b) => Number(tags[a.native].priority) - Number(tags[b.native].priority))
      .map((i) => i.native);
    const groupsFilterNames = Object.values(values)
      .sort((a, b) => Number(tags[a.native].priority) - Number(tags[b.native].priority))
      .map((i) => i.label);

    return {
      favouritesKey,
      EGroupTag: EGroupTag,
      partnerOriginals,
      tags: tags,
      groupsNames,
      groupsFilterNames,
      favouriteCourses: userFavs,
    };
  }, [displayedName, userFavs, isAuth, devStatus]);

  return {
    EGroupTag,
    groupsNativeNames,
    groupsFilterNames,
    favouritesKey,
    tags,
    partnerOriginals,
    favouriteCourses,
  };
};
