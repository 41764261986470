import { useState } from 'react';

interface Params {
  handleLeftTop: () => void;
  handleRightBottom: () => void;
  minSwipeDistance?: number;
  direction?: 'vertical' | 'horizontal';
}

export const useSwipe = ({ handleLeftTop, handleRightBottom, minSwipeDistance = 50, direction = 'horizontal' }: Params) => {
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [currentPosition, setCurrentPosition] = useState(null);
  const [isSwiped, setIsSwiped] = useState(false);

  const onTouchStart = (e: any) => {
    setTouchEnd(null);
    if (direction === 'horizontal') {
      setTouchStart(e.targetTouches[0].clientX);
      return;
    }
    setTouchStart(e.targetTouches[0].clientY);
  };

  const onTouchMove = (e: any) => {
    if (direction === 'horizontal') {
      setTouchEnd(e.targetTouches[0].clientX);
      setCurrentPosition(e.targetTouches[0].clientX);
      return;
    }
    setTouchEnd(e.targetTouches[0].clientY);
    setCurrentPosition(e.targetTouches[0].clientY);
  };

  const onTouchEnd = (e: any) => {
    if (touchStart && touchEnd) {
      const distance = touchStart - touchEnd;
      const isLeftSwipe = distance > minSwipeDistance;
      const isRightSwipe = distance < minSwipeDistance;
      if (isLeftSwipe || isRightSwipe) {
        setIsSwiped(true);
        isLeftSwipe ? handleLeftTop() : handleRightBottom();
      }
    }
  };

  return {
    onTouchStart,
    onTouchMove,
    onTouchEnd,
    isSwiped,
    startPosition: touchStart,
    endPosition: touchEnd,
    currentPosition,
  };
};
