enum EEnvs {
  StaticHostPath = 'REACT_APP_STATIC_HOST',
  BackendUrl = 'REACT_APP_BACKEND_URL',
  WebSocketUrl = 'REACT_APP_WEBSOCKET_URL',
  TelegramBotName = 'REACT_APP_TELEGRAM_BOT_NAME',
  TelegramBotLink = 'REACT_APP_MINIAPP_BOT_LINK',
  TelegramMiniAppName = 'REACT_APP_TELEGRAM_MINIAPP_NAME',
  GoogleClientId = 'REACT_APP_GOOGLE_CLIENT_ID',
  WalletConnectProjectId = 'REACT_APP_WALLET_CONNECT_PROJECT_ID',
  TonManifestUrl = 'REACT_APP_TON_MANIFEST_URL',
}

class RequiredEnvNotDefinedError extends Error {}

function parseEnv(name: EEnvs, required = true) {
  const value = process.env[name];
  if (!value && required) {
    throw new RequiredEnvNotDefinedError(name);
  }

  return value ?? null;
}

const TelegramBotName = parseEnv(EEnvs.TelegramBotName);
const TelegramMiniAppName = parseEnv(EEnvs.TelegramMiniAppName);
const GoogleClientId = parseEnv(EEnvs.GoogleClientId);
const TelegramBotLink = parseEnv(EEnvs.TelegramBotLink);
const WalletConnectProjectId = parseEnv(EEnvs.WalletConnectProjectId);
const StaticHostPath = parseEnv(EEnvs.StaticHostPath);
const BackendUrl = parseEnv(EEnvs.BackendUrl);
const WebSocketUrl = parseEnv(EEnvs.WebSocketUrl);
const TonManifestUrl = parseEnv(EEnvs.TonManifestUrl);

export const Environment = {
  StaticHostPath: StaticHostPath,
  BackendUrl: BackendUrl,
  WebSocketUrl: WebSocketUrl,
  TelegramBotName: TelegramBotName,
  TelegramMiniAppName: TelegramMiniAppName,
  GoogleClientId: GoogleClientId,
  TelegramBotLink: TelegramBotLink,
  WalletConnectProjectId: WalletConnectProjectId,
  TonManifestUrl: TonManifestUrl,
};
