import { createApi } from '@reduxjs/toolkit/query/react';
import { ISiteSettings } from 'interface';
import { customFetchBase } from 'store/utils';

export const appApi = createApi({
  reducerPath: 'appApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    //TODO change response type
    getSettings: builder.query<ISiteSettings, void>({
      query: () => ({ url: '/app/settings' }),
    }),
  }),
});

export const { useLazyGetSettingsQuery } = appApi;
