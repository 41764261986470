import { LOCAL_STORAGE_KEYS } from 'app/constants/app';

type UsabilityData = {
  isRegistratedWithEmail: boolean;
};

// Service for usability features like preferred order of some things, notifications, etc.
class UsabilityService {
  private data: UsabilityData = {
    isRegistratedWithEmail: false,
  };
  private storageKey: LOCAL_STORAGE_KEYS;

  constructor() {
    this.storageKey = LOCAL_STORAGE_KEYS.META_STORAGE;
    this.data = JSON.parse(localStorage.getItem(this.storageKey) || '{}');
  }

  getAll() {
    return this.data;
  }

  get<K extends keyof UsabilityData>(key: K) {
    return this.data[key];
  }

  set<K extends keyof UsabilityData>(key: K, value: UsabilityData[K]) {
    this.data[key] = value;
    localStorage.setItem(this.storageKey, JSON.stringify(this.data));
  }

  isRegistratedWithEmail() {
    return this.data.isRegistratedWithEmail;
  }

  setIsRegistratedWithEmail(value: boolean) {
    this.data.isRegistratedWithEmail = value;
    localStorage.setItem(this.storageKey, JSON.stringify(this.data));
  }
}

export const usabilityService = new UsabilityService();
