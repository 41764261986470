import { ROUTES } from 'app/constants';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useNavigateToAuth = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const navToAuth = useCallback(
    (path = pathname) => {
      navigate(ROUTES.AUTH, { state: { prev: path } });
    },
    [pathname]
  );

  return navToAuth;
};

export default useNavigateToAuth;
