import { LOCAL_STORAGE_KEYS } from 'app/constants';

class TokenService {
  static getRefreshToken() {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  }
  static getAccessToken() {
    return localStorage.getItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
  }
  static setRefreshToken(token: string) {
    return localStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, token);
  }
  static setAccessToken(token: string) {
    return localStorage.setItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN, token);
  }
  static setRemoveTokens() {
    localStorage.removeItem(LOCAL_STORAGE_KEYS.ACCESS_TOKEN);
    localStorage.removeItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN);
  }
}

export const tokenService = TokenService;
