import { createSlice } from '@reduxjs/toolkit';

import { coursesConstructorAPI } from './coursesConstructor.api';
import { ESlideTypes } from 'store';
import { generateAnswersBySlideType, generateExampleNodes } from './static';
import { RootStateType } from 'store';
import { ISlideFull } from 'interface/courses';

interface IInitialState {
  courses: any;
  chapters: any;
  lessons: any;
  slides: ISlideFull[];
}

const initialState: IInitialState = {
  courses: [],
  chapters: [],
  lessons: [],
  slides: [],
};

export const NEW_ITEM_ID = 'newItemId';

const coursesConstructorSlice = createSlice({
  name: 'coursesConstructor',
  initialState,
  reducers: {
    // Chapters
    deleteChapter: (state, action) => {
      state.chapters = state.chapters.filter((chapter: any) => chapter._id !== action.payload);
    },

    addEmptyChapter: (state, action) => {
      const { courseId } = action.payload;

      const emptyChapter = {
        _id: NEW_ITEM_ID,
        name: '',
        courseId,
      };

      state.chapters = [...state.chapters, emptyChapter];
    },

    discardAddChapter: (state) => {
      state.chapters = state.chapters.filter((item: any) => item._id !== NEW_ITEM_ID);
    },

    // Lessons
    deleteLesson: (state, action) => {
      state.lessons = state.lessons.filter((lesson: any) => lesson._id !== action.payload);
    },

    addEmptyLesson: (state, action) => {
      const { chapterId, type, id } = action.payload;

      const newLesson = {
        _id: id,
        name: '',
        challengeRewards: [],
        type,
        estimatedTime: 0,
        lives: 0,
        chapterId,
      };

      state.lessons = [...state.lessons, newLesson];
    },

    discardAddLesson: (state, _) => {
      state.lessons = state.lessons.filter((item: any) => item._id !== NEW_ITEM_ID);
    },

    // Slides
    createEmptyTestSlide: (state, action) => {
      const { type, lessonId, slideIndex, name } = action.payload;
      const index = slideIndex ?? state.slides.length;

      const { answers, rightAnswers } = generateAnswersBySlideType(type);
      const nodes = generateExampleNodes(type);

      const newSlide: ISlideFull = {
        name: name,
        lessonId,
        typeOfSlide: type === ESlideTypes.TEST ? ESlideTypes.MULTICHOICE : type,
        slideType: {
          answers,
          rightAnswers,
          isAnyCorrect: false,
          nodes,
        },
        content: [],
        rewards: [],
        rewardExp: 0,
        _id: NEW_ITEM_ID,
        slideIndex: index,
      };

      state.slides.splice(index, 0, newSlide);
    },

    // Widget
    addEmptyWidget: (state, action) => {
      const { slideId, widgetType } = action.payload;
      const slide = state.slides.find((item: any) => item._id === slideId);
      if (!slide) {
        return;
      }

      const newSlide = {
        ...slide,
        content: [...slide.content, { type: widgetType }],
      };

      state.slides = state.slides.map((item: any) => (item._id !== slideId ? item : newSlide));
    },

    changeWidget: () => {},

    reorderSlides: (state, action) => {
      state.slides = action.payload;
    },

    updateSlide: (state, action) => {
      const slide = action.payload;
      const slideIdx = state.slides.findIndex((item) => item._id === slide._id);
      if (slideIdx < 0) {
        return;
      }

      state.slides[slideIdx] = slide;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(coursesConstructorAPI.endpoints.fetchCourses.matchFulfilled, (state, action) => {
      state.courses = action.payload;
    });

    // Chapters
    builder.addMatcher(coursesConstructorAPI.endpoints.fetchChapters.matchFulfilled, (state, action) => {
      const chapters = action.payload?.chapters;
      if (!chapters) {
        return;
      }

      state.chapters = chapters;
    });

    builder.addMatcher(coursesConstructorAPI.endpoints.updateChapter.matchFulfilled, (state, action) => {
      const newChapter = action.payload;

      state.chapters = state.chapters.map((item: any) => (item._id === newChapter._id ? newChapter : item));
    });

    builder.addMatcher(coursesConstructorAPI.endpoints.createChapter.matchFulfilled, (state, action) => {
      state.chapters = state.chapters.map((item: any) => (item._id === NEW_ITEM_ID ? action.payload : item));
    });

    // Lessons
    builder.addMatcher(coursesConstructorAPI.endpoints.fetchLessons.matchFulfilled, (state, action) => {
      const lessons = action.payload?.lessons;
      if (!lessons) {
        return;
      }

      state.lessons = lessons;
    });

    builder.addMatcher(coursesConstructorAPI.endpoints.updateLesson.matchFulfilled, (state, action) => {
      const newLesson = action.payload;

      state.lessons = state.lessons.map((item: any) => (item._id === newLesson._id ? newLesson : item));
    });

    builder.addMatcher(coursesConstructorAPI.endpoints.addLesson.matchFulfilled, (state, action) => {
      const newLesson = action.payload;

      state.lessons = state.lessons.map((item: any) => (item._id === NEW_ITEM_ID ? newLesson : item));
    });

    // Slides
    builder.addMatcher(coursesConstructorAPI.endpoints.fetchSlides.matchFulfilled, (state, action) => {
      const slides = action.payload?.slides;
      if (!slides) {
        return;
      }

      state.slides = slides;
    });

    builder.addMatcher(coursesConstructorAPI.endpoints.deleteSlide.matchFulfilled, (state, action) => {
      const { meta } = action;
      state.slides = state.slides.filter((item: any) => item._id !== meta.arg.originalArgs);
    });

    builder.addMatcher(coursesConstructorAPI.endpoints.updateSlideWidgets.matchPending, (state, action) => {
      const { meta } = action;
      const newSlide = meta.arg.originalArgs.newSlide;
      if (!newSlide) {
        return;
      }

      state.slides = state.slides.map((item: any) => (item._id === newSlide._id ? newSlide : item));
    });

    builder.addMatcher(coursesConstructorAPI.endpoints.updateSlideWidgets.matchRejected, (state, action) => {
      const { meta } = action;
      const prevSlide = meta.arg.originalArgs.newSlide;
      if (!prevSlide) {
        return;
      }

      state.slides = state.slides.map((item: any) => (item._id === prevSlide._id ? prevSlide : item));
    });

    builder.addMatcher(coursesConstructorAPI.endpoints.updateSlideWidgets.matchFulfilled, (state, action) => {
      const { meta, payload: updatedSlide } = action;
      const slideId = meta.arg.originalArgs.slideId;
      const slide = state.slides.find((item: any) => item._id === slideId);
      if (!slide || !slideId) {
        return;
      }

      state.slides = state.slides.map((item: any) => (item._id === slideId ? updatedSlide : item));
    });

    builder.addMatcher(coursesConstructorAPI.endpoints.deleteWidget.matchFulfilled, (state, action) => {
      const { payload: updatedSlide } = action;

      state.slides = state.slides.map((item: any) => (item._id === updatedSlide._id ? updatedSlide : item));
    });

    builder.addMatcher(coursesConstructorAPI.endpoints.addAttachment.matchFulfilled, (state, action) => {
      const { payload: updatedSlide } = action;

      state.slides = state.slides.map((item: any) => (item._id === updatedSlide._id ? updatedSlide : item));
    });

    builder.addMatcher(coursesConstructorAPI.endpoints.deleteAttachment.matchFulfilled, (state, action) => {
      const { payload: updatedSlide } = action;
      if (!updatedSlide) {
        return;
      }

      state.slides = state.slides.map((item: any) => (item._id === updatedSlide._id ? updatedSlide : item));
    });
  },
});

export const { actions, reducer } = coursesConstructorSlice;

export const getSlides = (state: RootStateType) => state.coursesConstructor.slides;
