import * as Styled from './styles';

import { BsCheck2 } from 'react-icons/bs';
import { IoCloseOutline } from 'react-icons/io5';
import { ReactComponent as Alert } from 'assets/icons/alert__semi-rounded.svg';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import clsx from 'clsx';

enum ETypes {
  CORRECT = 'correct',
  INCORRECT = 'incorrect',
  DECLINED = 'declined',
}

type Props = {
  type?: 'correct' | 'incorrect' | 'declined';
  label?: string | null;
  subtext?: string | null;
};

const labels = {
  [ETypes.CORRECT]: 'Correct Answer!',
  [ETypes.INCORRECT]: 'Answer is Incorrect',
  [ETypes.DECLINED]: 'Because you have done this test before, rewards will not be received',
};

const icons = {
  [ETypes.CORRECT]: <BsCheck2 />,
  [ETypes.INCORRECT]: <IoCloseOutline />,
  [ETypes.DECLINED]: <Alert />,
};

export default function Answer({ type = ETypes.DECLINED, label = null, subtext = null }: Props) {
  const theme = useSelector(getCurrentTheme);
  return (
    <Styled.Container className={clsx(type, theme)}>
      <Styled.Icon>{icons[type]}</Styled.Icon>
      <Styled.TextWrap>
        <Styled.Text>{label ?? labels[type]}</Styled.Text>
        <Styled.Subtext>{subtext}</Styled.Subtext>
      </Styled.TextWrap>
    </Styled.Container>
  );
}
