import { EGroupTag, LANGS } from 'app/constants';

export interface ISpaceDesc {
  language: LANGS;
  title: string;
}

export interface ISpaceCustomization {
  leaderboards?: {
    images?: {
      bgImage?: string;
      bannerBgImage?: string;
    };
    accentColor?: string;
    isBannerShow?: boolean;
    customBannerLabel?: string;
    customBannerMonthlyLabel?: string;
  };
  auth?: {
    images?: {
      customLogo?: string;
      bgImage?: string;
      mobileBgImage?: string;
    };
  };
  lessonPage?: {
    images?: {
      bgImage?: string;
    };
    accentColor?: string;
  };
  spacePage?: {
    images?: {
      bgImage?: string;
    };
  };
}

export interface ISpace {
  _id: string;
  users: ISpaceUser[];
  name: string;
  link: string;
  description: ISpaceDesc[];
  image: string;
  imageThumb: string;
  tags: EGroupTag[];
  isVerified: boolean;
  customization?: ISpaceCustomization;
  defaultLeaderboard?: string;
}

export interface ISpaceShort {
  name: string;
  imageThumb: string;
  customization?: {
    imageLeaderboardBackground?: string;
    accentColor?: string;
  };
}

export interface IMySpacesResponseItem extends Omit<ISpace, 'users'> {
  users: IMySpaceRespUser[];
}

export interface ISpaceUser {
  roles: ESpaceRoles[];
  userId: {
    _id: string;
    nickname: string;
    avatar: string;
  };
  isSub?: boolean;
}

export interface IMySpaceRespUser extends Omit<ISpaceUser, 'userId'> {
  userId: string;
}

export enum ESpaceRoles {
  BASIC = 'BASIC',
  ADMIN = 'ADMIN',
  OWNER = 'OWNER',
}

export interface IAnalyticsCourse {
  name: string;
  estimatedTime: string;
  started: number;
  finished: number;
  completion: number;
  imageCover: string;
  isQuest: boolean;
}
