import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'store';
import { ISettingsBook } from '../../interface';

interface IInitialState {
  settings: ISettingsBook;
}

const initialState: IInitialState = {
  settings: {
    brightness: 100,
    fontSize: 18,
    color: null,
  },
};

export const booksSlice = createSlice({
  name: 'books',
  initialState,
  reducers: {
    setSettings: (state, action: PayloadAction<ISettingsBook>) => {
      state.settings = action.payload;
    },
    setColor: (state, action: PayloadAction<string>) => {
      state.settings.color = action.payload;
    },
  },
});

export const { setSettings, setColor } = booksSlice.actions;
export const getSettingsBook = (state: RootStateType) => state.books.settings;
export default booksSlice.reducer;
