import { useCallback, useEffect, useReducer } from 'react';
import './styles.css';
import { useGetCurrentTheme } from 'hooks';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { getPartnerId, useLazyGetPartnerLogoQuery } from 'store';
import { Arc } from './arcs';
import mightyLogo from 'assets/logos/loader-logo.png';

type Props = {
  size?: number;
  isFixed?: boolean;
  isLoading?: boolean;
};

type State = {
  isClosing: boolean;
  isClosed: boolean;
  partnerLogoUrl: string | null;
  isLogoLoaded: boolean;
};

const initialState: State = {
  isClosed: false,
  isClosing: false,
  isLogoLoaded: false,
  partnerLogoUrl: null,
};

const PartnerLoader = ({ size = 200, isFixed, isLoading }: Props) => {
  const theme = useGetCurrentTheme();
  const partnerId = useSelector(getPartnerId);
  const [getPartnerLogo] = useLazyGetPartnerLogoQuery();
  const [{ isClosed, isClosing, isLogoLoaded, partnerLogoUrl }, updateState] = useReducer(
    (prev: State, next: Partial<State>) => ({ ...prev, ...next }),
    initialState
  );

  const handleImageLoad = () => {
    updateState({ isLogoLoaded: true });
  };

  useEffect(() => {
    document.documentElement.style.overflowY = 'hidden';

    return () => {
      document.documentElement.style.overflowY = 'unset';
    };
  }, []);

  useEffect(() => {
    if (!!partnerId) {
      getPartnerLogo(partnerId.replaceAll('_', ' '))
        .unwrap()
        .then((partnerLogoUrl) => {
          updateState({ partnerLogoUrl });
        })
        .catch(console.error);
    }
  }, [partnerId]);

  useEffect(() => {
    if (!isLoading) {
      requestAnimationFrame(() => updateState({ isClosing: true }));
    }
  }, [isLoading]);

  const handleAnimationEnd = useCallback(() => {
    if (isClosing) {
      requestAnimationFrame(() => updateState({ isClosed: true }));
    }
  }, [isClosing]);

  if (isClosed) {
    return null;
  }

  return (
    <motion.div
      initial={{ opacity: 1 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      id="logo-loader"
      className={`container ${theme} ${isClosing || isClosed ? 'closing' : null}`}
      style={{ position: isFixed ? 'fixed' : 'absolute' }}
      onAnimationEnd={handleAnimationEnd}
    >
      <div className={`animation center-positioned`} style={{ width: size, height: size, transition: 'opacity 0.3s', opacity: isLogoLoaded ? 1 : 0 }}>
        <div className={`logo-container playing`}>
          <img src={!!partnerId ? partnerLogoUrl || '' : mightyLogo} onLoad={handleImageLoad} className="partner-logo" />
        </div>
      </div>
      <div
        className={`bg-round center-positioned playing ${theme}`}
        style={{
          width: size * 1.2,
          height: size * 1.2,
        }}
      >
        <div className="border-box center-positioned">
          <Arc theme={theme} index={1} />
        </div>
        <div className="border-box center-positioned">
          <Arc theme={theme} index={2} />
        </div>
        <div className="border-box center-positioned">
          <Arc theme={theme} index={3} />
        </div>
      </div>
    </motion.div>
  );
};

export default PartnerLoader;
