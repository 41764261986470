import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'store';
import { meditationApi } from './meditation.api';
import { IMeditationItem } from 'interface';

type ISelectedMeditation = IMeditationItem | null;

interface IInitialState {
  selectedMeditation: ISelectedMeditation;
  playingMeditation: string | null;
  meditations: IMeditationItem[];
}

const initialState: IInitialState = {
  selectedMeditation: null,
  playingMeditation: null,
  meditations: [],
};

export const meditationSlice = createSlice({
  name: 'meditation',
  initialState,
  reducers: {
    setSelectedMeditation: (state, action: PayloadAction<ISelectedMeditation>) => {
      state.selectedMeditation = action.payload;
    },
    setPlayingMeditation: (state, action: PayloadAction<string | null>) => {
      state.playingMeditation = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(meditationApi.endpoints.getMeditation.matchFulfilled, (state, action: PayloadAction<IMeditationItem[]>) => {
      state.meditations = action.payload;
    });
  },
});

export const { setSelectedMeditation, setPlayingMeditation } = meditationSlice.actions;
export const getSelectedMeditation = (state: RootStateType) => state.meditation.selectedMeditation;
export const getPlayingMeditation = (state: RootStateType) => state.meditation.playingMeditation;

export const getMeditations = (state: RootStateType) => state.meditation.meditations;
export default meditationSlice.reducer;
