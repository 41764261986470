import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from 'store/utils';

export const itemApi = createApi({
  reducerPath: 'itemApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    submitItem: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: '/items',
          method: 'post',
          body: data,
        };
      },
    }),
  }),
});

export const { useSubmitItemMutation } = itemApi;
