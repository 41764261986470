export enum NETWORKS {
  MAINNET = 'mainnet',
  GOERLI = 'goerli',
}

export enum PURCHASING_NETWORKS {
  ETHEREUM = 'ETHEREUM',
  AVAC = 'AVAC',
  // BSC = "BSC",
  MATIC = 'MATIC',
  POLYGON = 'POLYGON',
}

export enum NFT_NETWORKS {
  AVAC = '0xa86a',
  ETH = '0x1',
  // GOERLI = "0x5",
  POLYGON = '0x89',
  BSC = '0x38',
}

// "0x1" | "0x3" | "0x4" | "0x5" | "0x2a" | "0x89" | "0x13881" | "0x38" | "0x61" | "0xa86a" | "0xa869" | "0xfa" | "0x19" | "0x152"

export enum MIGHTY_SHOP_ADDRESSES {
  ETHEREUM = '0x98272d7fef1a32709D0b79F630f7Ed9aAc112316',
  AVAC = '0xF1e26E170c7AaC5c5487E16Fc967b57046DC2C7E',
  BSC = '0xFc9558CB4BA75d2691a93CF1032A6CA49375a733',
  MATIC = '0x9B804E7d474Ec734EA33535a3d10c31D6019B550',
}

const NATIVE_NAMES = {
  ETHEREUM: 'ETHEREUM',
  AVAC: 'AVAC',
  BSC: 'BSC',
  MATIC: 'MATIC',
};

const CHAINS = {
  ETH: 'eth',
  GOERLI: 'goerli',
  SEPOLIA: 'sepolia',
  POLYGON: 'polygon',
  MUMBAI: 'mumbai',
  BSC: 'bsc',
  BSC_TESTNET: 'bsc testnet',
  AVALANCHE: 'avalanche',
  AVALANCHE_TESTNET: 'avalanche testnet',
  FANTOM: 'fantom',
  CRONOS: 'cronos',
  CRONOS_TESTNET: 'cronos testnet',
};

const HEXES = {
  ETH: '0x1',
  ETHEREUM: '0x1',
  GOERLI: '0x5',
  SEPOLIA: '0xaa36a7',
  POLYGON: '0x89',
  MATIC: '0x89',
  MUMBAI: '0x13881',
  BSC: '0x38',
  BSC_TESTNET: '0x61',
  AVALANCHE: '0xa86a',
  AVAC: '0xa86a',
  AVALANCHE_TESTNET: '0xa869',
  FANTOM: '0xfa',
  CRONOS: '0x19',
  CRONOS_TESTNET: '0x152',
};

export const BLOCKCHAINS = {
  ETHEREUM: {
    chainName: CHAINS.ETH,
    hex: HEXES.ETH,
    nativeVenlyName: NATIVE_NAMES.ETHEREUM,
    venlyAddress: '0x98272d7fef1a32709D0b79F630f7Ed9aAc112316',
  },
  AVAC: {
    chainName: CHAINS.AVALANCHE,
    hex: HEXES.AVALANCHE,
    nativeVenlyName: NATIVE_NAMES.AVAC,
    venlyAddress: '0xF1e26E170c7AaC5c5487E16Fc967b57046DC2C7E',
  },
  BSC: {
    chainName: CHAINS.BSC,
    hex: HEXES.BSC,
    nativeVenlyName: NATIVE_NAMES.BSC,
    venlyAddress: '0xFc9558CB4BA75d2691a93CF1032A6CA49375a733',
  },
  MATIC: {
    chainName: CHAINS.POLYGON,
    hex: HEXES.POLYGON,
    nativeVenlyName: NATIVE_NAMES.MATIC,
    venlyAddress: '0x9B804E7d474Ec734EA33535a3d10c31D6019B550',
  },
};

export const SCANS = {
  '0x1': 'https://etherscan.io/',
  '0x89': 'https://polygonscan.com/',
  '0x38': 'https://bscscan.com/',
  '0xa86a': 'https://snowtrace.io/',
};
