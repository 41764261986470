import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from 'store/utils';
import { IFriendsItem, IProfile } from 'interface';

export const friendsApi = createApi({
  reducerPath: 'friendsApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    findUserByInput: builder.query<IProfile, string>({
      query: (input) => {
        return {
          url: `/friends/find/${input}`,
        };
      },
    }),
    getMyPendingFriendsRequests: builder.query<IFriendsItem[], void>({
      query: () => ({
        url: '/friends/requests/pending/me',
      }),
    }),
  }),
});

export const { useLazyFindUserByInputQuery, useLazyGetMyPendingFriendsRequestsQuery } = friendsApi;
