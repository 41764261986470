import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from '../utils';
import { IRewardMinimal } from 'store';
import { IAnswer } from './types';

export const answersApi = createApi({
  reducerPath: 'answersApi',
  baseQuery: customFetchBase,
  tagTypes: ['Answers'],
  endpoints: (builder) => ({
    getAnswersBySlideId: builder.query<{ items: IAnswer[] }, string>({
      query: (id) => ({
        url: `/hand-checked-answer/pending/by-slide-id/${id}`,
      }),
      providesTags: ['Answers'],
    }),
    getAnswersByChapterId: builder.query<{ items: IAnswer[] }, string>({
      query: (id) => ({
        url: `/hand-checked-answer/pending/by-chapter-id/${id}`,
      }),
      providesTags: ['Answers'],
    }),
    getAnswersByLessonId: builder.query<{ items: IAnswer[] }, string>({
      query: (id) => ({
        url: `/hand-checked-answer/pending/by-lesson-id/${id}`,
      }),
      providesTags: ['Answers'],
    }),
    getAnswersByCourseId: builder.query<{ items: IAnswer[] }, string>({
      query: (id) => ({
        url: `/hand-checked-answer/pending/by-course-id/${id}`,
      }),
      providesTags: ['Answers'],
    }),
    archiveAnswer: builder.mutation<any, { id: string; rewards?: IRewardMinimal[]; slideId: string }>({
      query: (payload) => ({
        url: '/hand-checked-answer/archive',
        method: 'PUT',
        body: payload,
      }),
    }),
  }),
});

export const {
  useGetAnswersBySlideIdQuery,
  useLazyGetAnswersByChapterIdQuery,
  useLazyGetAnswersByCourseIdQuery,
  useLazyGetAnswersByLessonIdQuery,
  useArchiveAnswerMutation,
  useLazyGetAnswersBySlideIdQuery,
} = answersApi;
