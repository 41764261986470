import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'store';

export type TMobilePanels = 'none' | 'notifications' | 'links' | 'shop' | 'home' | 'user';

interface IInitialState {
  currentMobilePanel: TMobilePanels;
}

const initialState: IInitialState = {
  currentMobilePanel: 'none',
};

export const mobilePanelSlice = createSlice({
  name: 'mobilePanel',
  initialState,
  reducers: {
    setCurrentMobilePanel: (state, action: PayloadAction<TMobilePanels>) => {
      state.currentMobilePanel = action.payload;
    },
  },
  extraReducers: (builder) => {},
});

export const { setCurrentMobilePanel } = mobilePanelSlice.actions;

export const getCurrentMobilePanel = (state: RootStateType) => state.mobile.currentMobilePanel;

export default mobilePanelSlice.reducer;
