import { CHESTS_WEIGHT, EChestRewardRarity } from 'app/constants';
import { useGetShopItems } from 'components/pages/shop/hooks';
import { EChestFungibleType, EGradeNumber, EItemFungibleType, IChest, IDatabaseItemQuantified } from 'interface';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getItems } from 'store';

const CHESTS = [
  {
    fungibleType: EChestFungibleType.NatureChest,
    backgroundImage: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/uncommon-bg-1732201954049.webp',
    grade: 'uncommon',
    grand: 545455,
  },
  {
    fungibleType: EChestFungibleType.VioletChest,
    backgroundImage: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/epic-bg-1732201748536.webp',
    grade: 'epic',
    grand: 545456,
  },
  {
    fungibleType: EChestFungibleType.AquaChest,
    backgroundImage: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/rare-bg-1732201898270.webp',
    grade: 'rare',
    grand: 545456,
  },
  // {
  //   fungibleType: 'stone-chest',
  //   backgroundImage: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/common-bg-1732201664878.webp',
  //   grade: 'common',
  //   grand: 545456,
  // },
  {
    fungibleType: EChestFungibleType.RoyalChest,
    backgroundImage: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/mythical-bg-1732201862086.webp',
    grade: 'mythical',
    grand: 545456,
  },
  {
    fungibleType: EChestFungibleType.DivineChest,
    backgroundImage: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/legendary-bg-1732201816497.webp',
    grade: 'legendary',
    grand: 545456,
  },
];

export const useGetChests = (chestType?: EChestFungibleType) => {
  const [state, setState] = useState<{ chests: IChest[]; chest?: IChest | null }>({
    chests: [],
    chest: null,
  });
  const items = useSelector(getItems);
  const { items: shopItems } = useGetShopItems({ page: 1, limit: 100 });
  const chestsRaw = CHESTS;
  useEffect(() => {
    if (!items) return;
    const chests: IChest[] = items
      .filter((item) => item.fungibleType && Object.values(EChestFungibleType).includes(item.fungibleType as EChestFungibleType))
      .filter((item) => shopItems.some((shopItem) => shopItem.item.id === item.id))
      .map((item) => {
        const chestFound = chestsRaw.find((chest) => chest.fungibleType === item.fungibleType)!;
        const chestFromShop = shopItems.find((shopItem) => shopItem.item.fungibleType === chestFound.fungibleType)!;
        const { mainRewardsTypes, rewardsTypes } = getRewardsTypes(chestFound.fungibleType);

        const rewards: IDatabaseItemQuantified[] = [];
        rewardsTypes.forEach((type) => {
          const item = items.find((i) => i.fungibleType === type);
          if (item) {
            rewards.push({ ...item, quantity: shopItems.find((shopItem) => shopItem.item.id === item.id)?.quantity ?? 1 });
          }
        });
        const mainRewards: IDatabaseItemQuantified[] = [];
        mainRewardsTypes.forEach((type) => {
          const item = items.find((i) => i.fungibleType === type);
          if (item) {
            mainRewards.push({ ...item, quantity: shopItems.find((shopItem) => shopItem.item.id === item.id)?.quantity ?? 1 });
          }
        });

        const chest: IChest = {
          ...item,
          fungibleType: chestFound.fungibleType as unknown as EChestFungibleType,
          quantity: chestFromShop.quantity,
          image: chestFromShop?.item?.imageThumb ?? ``,
          imageLarge: chestFromShop?.item?.image ?? ``,
          backgroundImage: chestFound.backgroundImage,
          title: item.name,
          price: chestFromShop.price,
          rewards: rewards,
          mainRewards: mainRewards,
          useOnlyIf: '',
          goodsId: chestFromShop.id,
          details: '',
        };
        return chest;
      });
    const chest = chests.find((chest) => chest.fungibleType === chestType) ?? null;
    const chestsSorted = chests.sort(
      (a, b) => EGradeNumber[a.grade.toUpperCase() as keyof typeof EGradeNumber] - EGradeNumber[b.grade.toUpperCase() as keyof typeof EGradeNumber]
    );

    setState({ chests: chestsSorted, chest });
    return () => {
      setState({ chests: [], chest: null });
    };
  }, [chestType, chestsRaw, items, shopItems]);

  return { ...state };
};

const getRewardsTypes = (fungibleType: EChestFungibleType) => {
  const chest = CHESTS_WEIGHT[fungibleType];

  if (!chest) {
    return { rewardsTypes: [], mainRewardsTypes: [] };
  }

  const chestKeys = Object.keys(chest) as EItemFungibleType[];
  const raritiesKeys = Object.keys(EChestRewardRarity) as EChestRewardRarity[];

  const chestSorted = chestKeys.sort((a, b) => {
    const rarityA = chest[a]!;
    const rarityB = chest[b]!;
    const indexA = raritiesKeys.indexOf(rarityA);
    const indexB = raritiesKeys.indexOf(rarityB);
    return indexA - indexB;
  });
  const rewardsTypes: EItemFungibleType[] = chestSorted.slice(4) as EItemFungibleType[];
  const mainRewardsTypes: EItemFungibleType[] = chestSorted.slice(0, 4).reverse() as EItemFungibleType[];

  return { rewardsTypes, mainRewardsTypes };
};
