import { createApi } from '@reduxjs/toolkit/query/react';

import { courseParserFetchBase } from 'store/utils/courseParserFetchBase';

export type CourseParserAPIParams = {
  url: string;
  courseId: string;
  chapterId: string | null;
};

export const courseParserAPI = createApi({
  reducerPath: 'courseParserAPI',
  baseQuery: courseParserFetchBase,
  tagTypes: ['Slides', 'Lessons', 'Chapters', 'Courses'],
  endpoints: (build) => ({
    parseCourseFromUrl: build.mutation({
      query: (params: CourseParserAPIParams) => ({
        url: 'parse-url',
        method: 'POST',
        body: { ...params, backUrl: process.env.REACT_APP_BACKEND_URL },
      }),
    }),
  }),
});
