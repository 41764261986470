export enum FRIEND_STATUS {
  ADDFRIEND = 0,
  REQUESTED = 1,
  PENDING = 2,
  FRIENDS = 3,
}
export enum USER_ROLES {
  BASIC = 'BASIC',
  ADMIN = 'ADMIN',
  STUDY = 'STUDY',
  MODERATOR = 'MODERATOR',
  CREATOR = 'CREATOR',
}
