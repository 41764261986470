import { useCallback, useEffect, useMemo, useState } from 'react';

const duration = 1.5;

const variantStartLoading: any = {
  initial: {
    transform: 'rotate(38.615deg) scale(2.5)',
  },
  animate: (firstFrameIsExit: boolean) => ({
    rotate: ['38.615deg', '38.615deg', '0deg'],
    scale: !firstFrameIsExit ? [1, 2.5, 1] : [1, 0.9, 1],
    opacity: !firstFrameIsExit ? 1 : [1, 0.3],
    transition: {
      transform: {
        repeat: 0,
        duration: duration,
      },
      opacity: {
        repeat: Infinity,
        duration: duration,
      },
      scale: {
        repeat: Infinity,
        duration: duration,
      },
    },
  }),
  exit: {
    opacity: 0,
    transition: {
      opacity: {
        duration: 0.5,
      },
    },
  },
};

const variantEndLoading = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {},
};

const variantsImageLoading = {
  initial: {
    opacity: 0,
    y: 200,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      delay: 0.4,
    },
  },
  exit: {},
};

const variantWrapIsLoaded = {
  animate: {
    opacity: 0,
    transition: {
      delay: 0.3,
    },
  },
};

const variantsStart: any = {
  initial: {
    transform: 'rotate(38.615deg) scale(1)',
  },
  animate: {
    transform: ['rotate(38.615deg) scale(1)', 'rotate(38.615deg) scale(2.5)', 'rotate(0deg) scale(1)', 'rotate(0deg) scale(1)'],
    opacity: [1, 1, 1, 0],
    transition: {
      duration: duration,
    },
  },
  exit: {},
};

const variantsEnd = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: {
      delay: duration,
    },
  },
  exit: {},
};

const variantsImage = {
  initial: {
    opacity: 0,
    y: 200,
  },
  animate: {
    opacity: 1,
    y: 0,
    transition: {
      delay: duration + 0.1,
    },
  },
  exit: {},
};

const variantsWrap = {
  animate: (isLoading: undefined | boolean) => ({
    opacity: 0,
    transition: {
      delay: !isLoading ? duration * 2 : 100,
    },
  }),
};

const variantStartMemorySave = {
  animate: {
    opacity: [1, 0.3],
    transition: {
      duration: duration,
      repeat: Infinity,
    },
  },
  exit: {},
};

export const usePageLoader = ({ isLoading, setAnimateIsExit, saveMemory }: any) => {
  const [animationComplete, setAnimationComplete] = useState(false);
  const [firstFrameIsExit, setFirstFrameIsExit] = useState(false);

  useEffect(() => {
    let isMounted = true;
    const timer = setTimeout(() => {
      if (isMounted) {
        setFirstFrameIsExit(true);
      }
    }, duration * 1000);
    return () => {
      isMounted = false;
      clearTimeout(timer);
    };
  }, []);

  const currentVariantWrap = useMemo(() => {
    if (isLoading === undefined) {
      return variantsWrap;
    }
    if (!isLoading) {
      return variantWrapIsLoaded;
    }
    return {};
  }, [isLoading]);

  const currentVariantStart: any = useMemo(() => {
    if (isLoading === undefined) {
      return variantsStart;
    }
    if (saveMemory) {
      return variantStartMemorySave;
    }

    return variantStartLoading;
  }, [isLoading]);

  const currentVariantEnd: any = useMemo(() => (isLoading === undefined ? variantsEnd : variantEndLoading), [isLoading]);

  const currentVariantImage: any = useMemo(() => (isLoading === undefined ? variantsImage : variantsImageLoading), [isLoading]);

  const handleOnAnimateComplete = useCallback(() => {
    if (isLoading === undefined) {
      return setAnimationComplete(true);
    }
    if (!isLoading && setAnimateIsExit === undefined) {
      return setAnimationComplete(true);
    }
    if (!isLoading && setAnimateIsExit) {
      return setAnimateIsExit();
    }
  }, [isLoading, setAnimateIsExit]);

  return {
    animationComplete,
    firstFrameIsExit,
    currentVariantWrap,
    currentVariantStart,
    currentVariantEnd,
    currentVariantImage,
    handleOnAnimateComplete,
  };
};
