import styled, { css } from 'styled-components';
import frameBlue from 'assets/profile-frames/blue.svg';
import frameBlueSquare from 'assets/profile-frames/blue--square.svg';
import framePremium from 'assets/profile-frames/premium.svg';
import framePremiumSquare from 'assets/profile-frames/premium--square.svg';

export const Container = styled.div(
    () => css`
      display: flex;
      align-items: center;
      justify-content: center;

      width: 58px;
      height: 58px;
      aspect-ratio: 1 / 1;
      position: relative;

      .span {
        color: var(--basic-blue);
      }
      .avatar-noImage {
        display: flex;
        align-items: center;
        justify-content: center;
        background: var(--basic-light);
        &.dark {
          background: var(--basic-dark);
        }
      }

      &.framed {
        .avatar-image,
        .avatar-noImage {
          scale: 0.85;
        }
      }
      .hidden {
        display: none;
      }
    `
  ),
  Marker = styled.div(
    () => css`
      position: absolute;
      top: 80%;
      right: 5px;
      width: 15px;
      height: 15px;
      background: #d9d9d9;
      border: 3px solid var(--body-color);
      border-radius: 10px;
      z-index: 2;
      &.light {
        background: #afb4b8;
      }
      &.online {
        background: #2aab8e;
      }
    `
  ),
  Frame = styled.div(
    () => css`
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      aspect-ratio: 1 / 1;
      background-image: url(${frameBlue});
      background-size: cover;

      &.avatar--square {
        background-image: url(${frameBlueSquare});
      }
      &.premium {
        background-image: url(${framePremium});
        &.avatar--square {
          background-image: url(${framePremiumSquare});
        }
      }
    `
  ),
  AvatarImage = styled.img(
    () => css`
      max-width: 100%;
      max-height: 100%;
      min-width: 100%;
      min-height: 100%;
      scale: 1.03;
    `
  ),
  NoAvatarImage = styled.div(
    () => css`
      background: var(--font-color-strong);
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    `
  );
