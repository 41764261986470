import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from 'store/utils';
import { TCheckoutCreationBody, TCheckoutCreationResponce } from './types';
import { ESupportedFiats } from './data';

export const cryptoApi = createApi({
  reducerPath: 'cryptoApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    createCheckout: builder.mutation<TCheckoutCreationResponce, TCheckoutCreationBody>({
      query: (body) => {
        return {
          url: '/crypto/checkout/buy-premium',
          body,
          method: 'POST',
        };
      },
    }),
    getInvoiceById: builder.query<any, string>({
      query: (id) => {
        return {
          url: `/crypto/invoices/${id}`,
          method: 'GET',
        };
      },
    }),
    getBalance: builder.query<any, string>({
      query: (cryptoCurrency) => {
        return {
          url: `/crypto/balance/${cryptoCurrency}`,
          method: 'GET',
        };
      },
    }),
    getFiatCurrencies: builder.query<any, ESupportedFiats>({
      query: (currency) => {
        return {
          url: `/crypto/currencies/${currency}`,
          method: 'GET',
        };
      },
    }),
  }),
});

export const { useCreateCheckoutMutation, useLazyGetBalanceQuery, useLazyGetFiatCurrenciesQuery, useLazyGetInvoiceByIdQuery } = cryptoApi;
