import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from '../utils';

export const compileApi = createApi({
  reducerPath: 'compileApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    compilePythonCode: builder.mutation<string, string>({
      query: (script) => {
        return {
          url: `/compiler/python`,
          method: 'POST',
          body: { script },
        };
      },
    }),
  }),
});

export const { useCompilePythonCodeMutation } = compileApi;
