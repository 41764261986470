import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'store';
import { authApi } from '../auth';
import { LANGS, LOCAL_STORAGE_KEYS, THEMES } from 'app/constants';
import i18next from 'i18next';
import { appApi } from './app.api';
import { ISiteSettings } from 'interface';

export type TLoadingVariant = 'global' | 'default';

interface IInitialState {
  isInit: boolean;
  currentLanguage: LANGS;
  currentTheme: THEMES;
  lessonPageVolume: number;
  pageIsLoaded: boolean;
  smallPageIsLoading: boolean;
  settings: ISiteSettings;
}
// изначальный стейт (при первой загрузке приложения)
const initialState: IInitialState = {
  isInit: false,
  currentLanguage: LANGS.EN,
  currentTheme: THEMES.DARK,
  lessonPageVolume: 100,
  pageIsLoaded: false,
  smallPageIsLoading: false,
  settings: {
    displayedName: 'Mighty.',
    originalName: 'Mighty',
    partnerId: 1,
    discordLink: '',
    contactLink: '',
    globalLoaderLink: '',
    logos: {
      xlarge: '',
      large: '',
      regular: '',
      small: '',
      xsmall: '',
    },
  },
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsInitApp: (state, action: PayloadAction<boolean>) => {
      state.isInit = action.payload;
    },
    setCurrentLanguage: (state, action: PayloadAction<LANGS>) => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.LANGUAGE, action.payload);
      i18next.changeLanguage(action.payload);
      state.currentLanguage = action.payload;
    },
    setCurrentTheme: (state, action: PayloadAction<THEMES>) => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.THEME, action.payload);
      document.documentElement.classList.remove(`dark`, 'light');
      document.documentElement.classList.add(action.payload);
      state.currentTheme = action.payload;
    },
    setLessonPageVolume: (state, { payload }: PayloadAction<number>) => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.LESSON_PAGE_VOLUME, payload.toString());
      state.lessonPageVolume = payload;
    },
    setCurrentThemeWithoutSaving: (state, action: PayloadAction<THEMES>) => {
      document.documentElement.classList.remove(`dark`, 'light');
      document.documentElement.classList.add(action.payload);
      state.currentTheme = action.payload;
    },
    setPageIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.pageIsLoaded = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state, _) => {
      state.isInit = true;
    });
    builder.addMatcher(authApi.endpoints.refreshToken.matchRejected, (state, _) => {
      state.isInit = true;
    });
    builder.addMatcher(appApi.endpoints.getSettings.matchFulfilled, (state, action) => {
      state.settings = action.payload;
    });
  },
});
export const { setIsInitApp, setCurrentLanguage, setCurrentTheme, setCurrentThemeWithoutSaving, setPageIsLoaded, setLessonPageVolume } =
  appSlice.actions;

const getIsInitForSelector = (state: RootStateType) => state.app.isInit;
const getAppSettingsForSelector = (state: RootStateType) => state.app.settings;
const getCurrentLanguageForSelector = (state: RootStateType) => state.app.currentLanguage;
const getCurrentThemeForSelector = (state: RootStateType) => state.app.currentTheme;
const getPageIsLoadedForSelector = (state: RootStateType) => state.app.pageIsLoaded;
const getLessonPageVolumeForSelector = (state: RootStateType) => state.app.lessonPageVolume;

export const getIsInit = createSelector(getIsInitForSelector, (isInit) => isInit);
export const getAppSettings = createSelector(getAppSettingsForSelector, (settings) => settings);
export const getCurrentLanguage = createSelector(getCurrentLanguageForSelector, (currentLanguage) => currentLanguage);
export const getCurrentTheme = createSelector(getCurrentThemeForSelector, (currentTheme) => currentTheme);
export const getPageIsLoaded = createSelector(getPageIsLoadedForSelector, (pageIsLoaded) => pageIsLoaded);
export const getLessonPageVolume = createSelector(getLessonPageVolumeForSelector, (lessonPageVolume) => lessonPageVolume);
export default appSlice.reducer;
