import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import RequestToast from 'shared/ui/toasts/requestToast/request-toast';
import { getIsAuth, useLazyGetMyPendingFriendsRequestsQuery } from 'store';

export const useGetPendingFriendRequestsAsToasts = () => {
  const [get] = useLazyGetMyPendingFriendsRequestsQuery();
  const isAuth = useSelector(getIsAuth);

  useEffect(() => {
    if (isAuth) {
      get()
        .unwrap()
        .then((reqs) => {
          reqs.forEach((req) => {
            if (typeof req.requester !== 'string') {
              toast.info(<RequestToast type="friend" {...req.requester} />, { autoClose: false });
            }
          });
        });
    }
  }, [get, isAuth]);
};
