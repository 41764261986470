import { useCallback, useRef } from 'react';

type DebouncedFunction<T extends (...args: any[]) => void> = (callback: T, delay: number) => (...args: Parameters<T>) => void;

export const useDebounce = () => {
  const timer = useRef<null | ReturnType<typeof setTimeout>>(null);

  const debounce: DebouncedFunction<any> = useCallback((callback, delay) => {
    return (...args) => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      timer.current = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  }, []);

  return debounce;
};
