import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from 'store/utils';

export const statisticsApi = createApi({
  reducerPath: 'statisticsApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    pingOnline: builder.mutation({
      query: () => {
        return {
          url: `/statistics-time/pingOnline`,
          method: 'POST',
        };
      },
    }),

    fetchUsersMeasuresScores: builder.query({
      query: (params: { fromIndex: number; toIndex: number }) => {
        return {
          url: `statistics/usersMeasuresScores?pagination=${encodeURIComponent(JSON.stringify(params))}`,
          method: 'GET',
        };
      },
    }),
  }),
});
