import { createApi } from '@reduxjs/toolkit/query/react';
import { IBattle, IBattleMaterial } from 'interface';
import { customFetchBase } from 'store/utils';

export const battleApi = createApi({
  reducerPath: 'battleApi',
  baseQuery: customFetchBase,
  tagTypes: ['BATTLES', 'PENDING_BATTLES', 'TOPICS'],
  endpoints: (builder) => ({
    getTopicsForBattle: builder.query<IBattleMaterial[], void>({
      query: () => {
        return {
          url: '/battle/topics',
        };
      },
      providesTags: ['TOPICS'],
    }),
    getIfBattleStarted: builder.query<any, any>({
      query: (data) => {
        return {
          url: '/battle/is-started',
          method: 'GET',
          params: data,
        };
      },
    }),
    getBattleById: builder.query<IBattle, string>({
      query: (id) => {
        return {
          url: `/battle/${id}`,
        };
      },
    }),
    getPendingBattles: builder.query<IBattle[], void>({
      query: () => {
        return {
          url: '/battle/pending-battles',
        };
      },
      providesTags: ['BATTLES', 'PENDING_BATTLES'],
    }),
    getBattlesHistory: builder.query<IBattle[], void>({
      query: () => {
        return {
          url: '/battle/battles-history',
        };
      },
      providesTags: ['BATTLES'],
    }),
    createBattle: builder.mutation<IBattle, { group: string; opponentId: string | null }>({
      query: (data) => {
        return {
          url: '/battle/create',
          method: 'POST',
          body: data,
        };
      },
    }),
    startBattle: builder.mutation<IBattle, string>({
      query: (battleId) => ({
        url: '/battle/start',
        method: 'PUT',
        body: { battleId },
      }),
    }),
    rejectBattle: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: '/battle/reject',
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['PENDING_BATTLES'],
    }),
    checkAnswers: builder.mutation<any, { battleId: number; slideId: number; userAnswers: string[] }>({
      query: (data) => {
        return {
          url: '/battle/check-answer',
          method: 'PUT',
          body: data,
        };
      },
    }),
  }),
});

export const {
  useLazyGetTopicsForBattleQuery,
  useLazyGetIfBattleStartedQuery,
  useCreateBattleMutation,
  useLazyGetBattleByIdQuery,
  useRejectBattleMutation,
  useLazyGetBattlesHistoryQuery,
  useGetBattlesHistoryQuery,
  useLazyGetPendingBattlesQuery,
  useGetPendingBattlesQuery,
  useStartBattleMutation,
  useCheckAnswersMutation,
} = battleApi;
