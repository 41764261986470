import { EIcons, ESpecialIcons } from 'components/library/courseConstructor/components/Widget/components/TaskWidget/NewCustomizationMenu/constants';
import { TVariantWarningIcon } from '../../components/library/courseConstructor/components/Widget/components/WarningWidget/WarningIcons/types';

export interface IRewardMinimal {
  id: number;
  quantity: number;
}

export interface ISlideWidget {
  id: string;
  type: ESlideWidgetTypes;
  content: string;
  color?: string;
  icon?: TVariantWarningIcon | EIcons | ESpecialIcons;
  image?: string;
  title?: string;
  link?: string;
  author?: string;
  isRequired?: boolean;
}

export interface IAnswer {
  text: string;
}

export enum ESlideWidgetTypes {
  TEXT = 'Text',
  WARNING = 'Warning',
  CODE = 'Code',
  IMAGE = 'Image',
  VIDEO = 'Video',
  FILE = 'File',
  TASK = 'Task',
  QUOTE = 'Quote',
}

export enum ESlideTypes {
  INFO = 'Info',
  TEST = 'Test',
  MULTICHOICE = 'Multichoice',
  TYPEANSWER = 'Typeanswer',
  FILLBLOCKS = 'Fillblocks',
  FILLSPACES = 'Fillspaces',
  FILLSPACES_CODE = 'FillspacesCode',
  FILLBLOCKS_CODE = 'FillblocksCode',
}

export type TAIGeneratedTestType = ESlideTypes.MULTICHOICE | ESlideTypes.FILLSPACES | ESlideTypes.FILLBLOCKS | ESlideTypes.TYPEANSWER;
export const slideTypesForAIGeneratedTest: TAIGeneratedTestType[] = [
  ESlideTypes.MULTICHOICE,
  ESlideTypes.FILLSPACES,
  ESlideTypes.FILLBLOCKS,
  ESlideTypes.TYPEANSWER,
];

export type TCreateCourseDTO = {
  name: string;
  grade: string;
  description: { language: string; title: string }[];
  difficulty: string;
  estimatedTime: number;
  category: string;
  imageLogo: string;
  imageBanner: string;
  groups?: string[];
  bundles?: string[];
};

export type TEditCourseDTO = Omit<TCreateCourseDTO, 'imageLogo' | 'estimatedTime'>;

export type TParamsEditCourseMutation = {
  courseId: string;
  body: TEditCourseDTO;
};

export type TParamsEditImageCourseMutation = {
  courseId: string;
  body: string;
};

export enum ECourseDifficulties {
  BEGINNER = 'beginner',
  INTERMEDIATE = 'intermediate',
  ADVANCED = 'advanced',
}

export enum ECourseCategories {
  BLOCKCHAIN = 'blockchain & smart contracts',
  WEB_DEVELOPMENT = 'web development',
  DATA_SCIENCE = 'data science',
}

export type TWidgetFile = {
  name: string;
  filePath: string;
};
