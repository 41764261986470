import { useEffect } from 'react';
import { dispatch } from './useDispatch';
import { setCurrentTheme, setPartnerData } from 'store';
import { LOCAL_STORAGE_KEYS, THEMES } from 'app/constants';

const usePartnerParams = () => {
  const location = window.location.search;

  useEffect(() => {
    const searchParams = new URLSearchParams(location);
    const partnerID = searchParams.get('partnerID');
    const partnerToken = searchParams.get('partnerToken');
    const theme: any = searchParams.get(LOCAL_STORAGE_KEYS.THEME) ?? localStorage.getItem(LOCAL_STORAGE_KEYS.THEME) ?? THEMES.DARK;

    if ((partnerID && partnerToken) || theme) {
      if (partnerID && partnerToken) {
        dispatch(
          setPartnerData({
            partnerID: partnerID || null,
            partnerToken: partnerToken || null,
            theme: theme,
          })
        );
      }
      if (theme) {
        dispatch(setCurrentTheme(theme as THEMES));
      }
    }
  }, [location]);
};

export default usePartnerParams;
