import { useMemo } from 'react';

export const useGetMiniAppInitData = () => {
  const initData = useMemo(() => {
    if (window?.Telegram?.WebApp?.initData) {
      const firstLayerInitData = Object.fromEntries(new URLSearchParams(window.Telegram.WebApp.initData));

      const initData: Record<string, string> = {};

      for (const key in firstLayerInitData) {
        try {
          initData[key] = JSON.parse(firstLayerInitData[key]);
        } catch {
          initData[key] = firstLayerInitData[key];
        }
      }
      if (Object.keys(initData).length > 0) {
        return initData;
      }
    }
    return null;
  }, []);

  const startParam: string | null = useMemo(() => initData?.start_param ?? null, [initData]);

  return { startParam, initData };
};
