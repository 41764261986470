import { EChestFungibleType, EItemFungibleType } from 'interface';

export enum ITEM_IDS {
  EXP_TOKEN = 0,
  TIME_STONE = 2,
  EXP_POTION = 3,
  AMETHYST_STONE = 4,
  EMPEROR_KEY = 5,
  BLOOD_TALISMAN = 6,
}

export enum GRADES {
  COMMON = 'common',
  UNCOMMON = 'uncommon',
  RARE = 'rare',
  EPIC = 'epic',
  LEGENDARY = 'legendary',
  MYTHICAL = 'mythical',
  DIVINE = 'divine',
}

export const GRADES_COLORS = {
  [GRADES.COMMON]: '#B3B3B3',
  [GRADES.UNCOMMON]: '#68FFAF',
  [GRADES.RARE]: '#6487EF',
  [GRADES.EPIC]: '#C541C8',
  [GRADES.LEGENDARY]: '#FFB65D',
  [GRADES.MYTHICAL]: '#F15062',
  [GRADES.DIVINE]: '#F15062',
};

export enum ITEM_EFFECT_TYPE {
  INTERFACE_APPEARANCE = 'Interface Appearance',
}

export enum INTERFACE_APPEARANCE_TARGET {
  NAME = 'Name',
  GLOW = 'Glow',
  FONT = 'Font',
}

export enum EChestRewardRarity {
  ExtremelyLowChance = 'ExtremelyLowChance',
  LowChance = 'LowChance',
  MediumChance = 'MediumChance',
  HighChance = 'HighChance',
  VeryHighChance = 'VeryHighChance',
}

export const CHEST_REWARD_RARITY_LABELS = {
  [EChestRewardRarity.ExtremelyLowChance]: 'Extremely low chance',
  [EChestRewardRarity.LowChance]: 'Low chance',
  [EChestRewardRarity.MediumChance]: 'Medium chance',
  [EChestRewardRarity.HighChance]: 'High chance',
  [EChestRewardRarity.VeryHighChance]: 'Very high chance',
};

type ItemWeights = Partial<{ [key in EItemFungibleType]: EChestRewardRarity }>;

type ChestWeights = { [key in EChestFungibleType]?: ItemWeights };

export const CHESTS_WEIGHT: ChestWeights = {
  [EChestFungibleType.NatureChest]: {
    [EItemFungibleType.EnergyShard]: EChestRewardRarity.VeryHighChance,
    [EItemFungibleType.NaturePurse]: EChestRewardRarity.VeryHighChance,
    [EItemFungibleType.LifeShard]: EChestRewardRarity.HighChance,
    [EItemFungibleType.LuckShard]: EChestRewardRarity.HighChance,
    [EItemFungibleType.UnknownLiquid]: EChestRewardRarity.HighChance,
    [EItemFungibleType.SmallEnergyStone]: EChestRewardRarity.HighChance,
    [EItemFungibleType.ExpPotion]: EChestRewardRarity.MediumChance,
    [EItemFungibleType.ExperienceEssenceShard]: EChestRewardRarity.MediumChance,
    [EItemFungibleType.GreenInkBottle]: EChestRewardRarity.MediumChance,
    [EItemFungibleType.GreenLuminaryPowder]: EChestRewardRarity.MediumChance,
    [EItemFungibleType.VioletPurse]: EChestRewardRarity.LowChance,
    [EItemFungibleType.ScholarCoin]: EChestRewardRarity.LowChance,
    [EItemFungibleType.LargeEnergyStone]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.LargeEnergyPotion]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.ThePlagueNFT]: EChestRewardRarity.ExtremelyLowChance,
  },
  [EChestFungibleType.VioletChest]: {
    [EItemFungibleType.ExperienceEssenceShard]: EChestRewardRarity.VeryHighChance,
    [EItemFungibleType.EnergyShard]: EChestRewardRarity.HighChance,
    [EItemFungibleType.LifeShard]: EChestRewardRarity.HighChance,
    [EItemFungibleType.LuckShard]: EChestRewardRarity.HighChance,
    [EItemFungibleType.UnknownLiquid]: EChestRewardRarity.MediumChance,
    [EItemFungibleType.PurpleLuminaryPowder]: EChestRewardRarity.MediumChance,
    [EItemFungibleType.PurpleInkBottle]: EChestRewardRarity.MediumChance,
    [EItemFungibleType.Amethyst]: EChestRewardRarity.LowChance,
    [EItemFungibleType.EnhancedExpPotion]: EChestRewardRarity.LowChance,
    [EItemFungibleType.ScholarCoin]: EChestRewardRarity.LowChance,
    [EItemFungibleType.VioletPurse]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.RoyalPurse]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.AzukiBean]: EChestRewardRarity.ExtremelyLowChance,
  },
  [EChestFungibleType.AquaChest]: {
    [EItemFungibleType.ExperienceEssenceShard]: EChestRewardRarity.VeryHighChance,
    [EItemFungibleType.ExpPotion]: EChestRewardRarity.HighChance,
    [EItemFungibleType.EnergyShard]: EChestRewardRarity.HighChance,
    [EItemFungibleType.LifeShard]: EChestRewardRarity.HighChance,
    [EItemFungibleType.LuckShard]: EChestRewardRarity.HighChance,
    [EItemFungibleType.UnknownLiquid]: EChestRewardRarity.MediumChance,
    [EItemFungibleType.BlueLuminaryPowder]: EChestRewardRarity.MediumChance,
    [EItemFungibleType.BlueInkBottle]: EChestRewardRarity.MediumChance,
    [EItemFungibleType.ScholarCoin]: EChestRewardRarity.LowChance,
    [EItemFungibleType.EnhancedExpPotion]: EChestRewardRarity.LowChance,
    [EItemFungibleType.AquaPurse]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.NaturePurse]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.VioletPurse]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.SappySealsNFT]: EChestRewardRarity.ExtremelyLowChance,
  },
  [EChestFungibleType.DivineChest]: {
    [EItemFungibleType.ExperienceEssenceShard]: EChestRewardRarity.VeryHighChance,
    [EItemFungibleType.EnergyShard]: EChestRewardRarity.HighChance,
    [EItemFungibleType.LifeShard]: EChestRewardRarity.HighChance,
    [EItemFungibleType.LuckShard]: EChestRewardRarity.HighChance,
    [EItemFungibleType.UnknownLiquid]: EChestRewardRarity.MediumChance,
    [EItemFungibleType.YellowLuminaryPowder]: EChestRewardRarity.MediumChance,
    [EItemFungibleType.YellowInkBottle]: EChestRewardRarity.MediumChance,
    [EItemFungibleType.ScholarCoin]: EChestRewardRarity.LowChance,
    [EItemFungibleType.YellowDiamond]: EChestRewardRarity.LowChance,
    [EItemFungibleType.AquaPurse]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.VioletPurse]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.RoyalPurse]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.EmperorBadge]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.EmperorKey]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.USDC20]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.USDC30]: EChestRewardRarity.ExtremelyLowChance,
    [EItemFungibleType.USDC100]: EChestRewardRarity.ExtremelyLowChance,
  },
};
