import { useEffect, useState } from 'react';

interface IProps {
  startFrom: number;
  substructor?: number;
  interval?: number;
  onTimeout?: (() => any) | null;
}

export const useClock = ({ startFrom = 0, substructor = 1, interval = 1000, onTimeout = null }: IProps) => {
  const [clock, setClock] = useState(startFrom);

  useEffect(() => {
    const myInterval = setInterval(() => {
      setClock((currentClock) => {
        const nextClock = currentClock - substructor;
        if (nextClock > 0) {
          return nextClock;
        } else {
          if (typeof onTimeout === 'function') {
            onTimeout();
          }
        }
        return 0;
      });
    }, interval);

    return () => clearInterval(myInterval);
  }, [interval, substructor, onTimeout]);

  return { clock, onTimeout };
};
