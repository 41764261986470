import { EItemFungibleType } from 'interface';
import { GRADES } from './items';
import { THEMES } from './app';

export enum AVATAR_SHAPES {
  ROUND = 'round',
  SQUARE = 'square',
}

export const CUSTOMIZATION_BOTTLES = [
  EItemFungibleType.GrayInkBottle,
  EItemFungibleType.GreenInkBottle,
  EItemFungibleType.BlueInkBottle,
  EItemFungibleType.PurpleInkBottle,
  EItemFungibleType.YellowInkBottle,
  EItemFungibleType.RedInkBottle,
];

export const CUSTOMIZATION_POWDERS = [
  EItemFungibleType.GrayLuminaryPowder,
  EItemFungibleType.GreenLuminaryPowder,
  EItemFungibleType.BlueLuminaryPowder,
  EItemFungibleType.PurpleLuminaryPowder,
  EItemFungibleType.YellowLuminaryPowder,
  EItemFungibleType.RedLuminaryPowder,
];

export const GLOWS: Partial<Record<EItemFungibleType, string>> = {
  [EItemFungibleType.GrayLuminaryPowder]: 'default',
  [EItemFungibleType.GreenLuminaryPowder]: '#00723B',
  [EItemFungibleType.BlueLuminaryPowder]: '#2687DB',
  [EItemFungibleType.PurpleLuminaryPowder]: '#C947D3',
  [EItemFungibleType.YellowLuminaryPowder]: '#FF8C00',
  [EItemFungibleType.RedLuminaryPowder]: '#F4556D',
};

export const NAME_COLORS: Partial<Record<EItemFungibleType, { [THEMES.LIGHT]: string; [THEMES.DARK]: string }>> = {
  [EItemFungibleType.GrayInkBottle]: {
    light: 'default',
    dark: 'default',
  },
  [EItemFungibleType.GreenInkBottle]: {
    light: '#00723B',
    dark: '#29AB88',
  },
  [EItemFungibleType.BlueInkBottle]: {
    light: '#2687DB',
    dark: '#627EEA',
  },
  [EItemFungibleType.PurpleInkBottle]: {
    light: '#C947D3',
    dark: '#CA47CE',
  },
  [EItemFungibleType.YellowInkBottle]: {
    light: '#FF8C00',
    dark: '#FFBF66',
  },
  [EItemFungibleType.RedInkBottle]: {
    light: '#F4556D',
    dark: '#F14F63',
  },
};

export const NAME_COLORS_CARDS: Partial<Record<EItemFungibleType, { [THEMES.LIGHT]: string; [THEMES.DARK]: string }>> = {
  [EItemFungibleType.GrayInkBottle]: {
    light: 'default',
    dark: 'default',
  },
  [EItemFungibleType.GreenInkBottle]: {
    light: '#08782E',
    dark: '#29AB88',
  },
  [EItemFungibleType.BlueInkBottle]: {
    light: '#2C2C96',
    dark: '#627EEA',
  },
  [EItemFungibleType.PurpleInkBottle]: {
    light: '#872E79',
    dark: '#CA47CE',
  },
  [EItemFungibleType.YellowInkBottle]: {
    light: '#9A5300',
    dark: '#FFBF66',
  },
  [EItemFungibleType.RedInkBottle]: {
    light: '#811018',
    dark: '#F14F63',
  },
};

export const defaultCustomization = {
  name: {
    ...NAME_COLORS[EItemFungibleType.GrayInkBottle]!,
    cards: NAME_COLORS_CARDS[EItemFungibleType.GrayInkBottle]!,
  },
  glow: GLOWS[EItemFungibleType.GrayLuminaryPowder]!,
  font: GRADES.COMMON,
  avatarShape: AVATAR_SHAPES.ROUND,
};

export const DEFAULT_SPACE_LEADERBOARD_CUSTOMIZATION = {
  background: 'https://mighty-storage.fra1.digitaloceanspaces.com/static/images/leaderboardBg.webp',
  color: 'var(--red-color)',
};
