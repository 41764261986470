import { useEffect } from 'react';
import { useDisplay } from './useDisplay';
import { useNavigate } from 'react-router';
import { ROUTES } from 'app/constants';

export const useCheckIfMobileUnauth = () => {
  const { isMobile } = useDisplay();
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile) {
      navigate(ROUTES.AUTH);
    }
  }, [isMobile, navigate]);
};
