import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from 'store/utils';
import { ILeaderboardsResponse, TSpaceLeaderboardResponse, TSpaceSpecialLeaderboardResponse } from './leaderboard.types';

export const leaderboardApi = createApi({
  reducerPath: 'leaderboardApi',
  // refetchOnFocus: false,
  refetchOnReconnect: true,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getLeaderboardUsers: builder.query<ILeaderboardsResponse, void>({
      query: (query) => {
        return {
          url: '/leaderboards',
        };
      },
    }),
    getLeaderboardUsersBySpace: builder.query<TSpaceLeaderboardResponse, string>({
      query: (spaceName) => ({
        url: `/leaderboards/space/${spaceName}`,
      }),
    }),
    getSpecialLeaderboardUsersBySpace: builder.query<TSpaceSpecialLeaderboardResponse, { spaceName: string; token: string }>({
      query: ({ spaceName, token }) => ({
        url: `/leaderboards/partner/for-display/${spaceName}`,
        params: { token },
      }),
    }),
  }),
});

export const { useLazyGetLeaderboardUsersQuery, useLazyGetLeaderboardUsersBySpaceQuery, useLazyGetSpecialLeaderboardUsersBySpaceQuery } =
  leaderboardApi;
