import { EAuthMethods } from 'interface';
import { useAttachAuthMethodMutation, useLazyGetMyPartsQuery } from 'store';

type Props = {
  method: EAuthMethods;
};

export const useAttachUser = ({ method }: Props) => {
  const [attach, { isLoading: isAttaching }] = useAttachAuthMethodMutation();
  const [refetchUserData, { isFetching: isRefetchingUser }] = useLazyGetMyPartsQuery();

  const handleAttach = async (data: any) => {
    return attach({ type: method, data })
      .unwrap()
      .then(() => refetchUserData({ props: ['authMethods'] }));
  };
  return {
    handleAttach,
    isLoading: isAttaching || isRefetchingUser,
    isAttaching,
    isRefetchingUser,
  };
};
