import './wdyr';
import { Provider } from 'react-redux';
import { TonConnectUIProvider } from '@tonconnect/ui-react';
import { persistor, store } from 'store';
import './i18n';
import { PersistGate } from 'redux-persist/integration/react';
import App from 'app/app';
import { createRoot } from 'react-dom/client';

import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { StrictMode } from 'react';
import reportWebVitals from 'reportWebVitals';
import { WagmiWrapper } from 'features/wagmi';

if (window?.Telegram?.WebApp) {
  window.Telegram.WebApp.expand();
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <Provider store={store}>
    <WagmiWrapper>
      <PersistGate persistor={persistor}>
        <StrictMode>
          <TonConnectUIProvider manifestUrl={process.env.REACT_APP_TON_MANIFEST_URL} restoreConnection={false}>
            <App />
          </TonConnectUIProvider>
        </StrictMode>
      </PersistGate>
    </WagmiWrapper>
  </Provider>
);

reportWebVitals();
