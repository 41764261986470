import { Environment } from 'app/constants';
import { useGetUserData } from 'hooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useGetMiniAppInitData } from 'shared/utils';
import { getAppSettings } from 'store';

export const telegramShareLink = (url: string, text?: string) => () => {
  let link = `https://t.me/share/url?url=${encodeURIComponent(url)}`;

  if (text) {
    link += `&text=${encodeURIComponent(text)}`;
  }

  window.open(link, '_blank');
};

export const twitterShareLinkPost = (url: string, text?: string) => () => {
  let link = `https://twitter.com/intent/tweet?text=`;
  let content = encodeURIComponent(`${url} \n ${text || ''}`);
  link += content;

  window.open(link, '_blank');
};

export const twitterShareLinkMessage = (url: string, text?: string) => () => {
  let link = `https://twitter.com/share?text=${encodeURIComponent(text ?? '')}&url=${encodeURIComponent(url)}`;

  window.open(link, '_blank');
};

export const facebookShareLink = (url: string, text?: string) => () => {
  let link = `https://www.facebook.com/share.php?u=${encodeURIComponent(url)}`;
  window.open(link, '_blank');
};

export const instagramShareLink = (url: string, text?: string) => () => {
  let link = `https://www.instagram.com/?text=${encodeURIComponent(url + '\n' + text || '')}`;
  window.open(link, '_blank');
};

export const useMiniAppStoryMethods = (url: string, text: string) => {
  const { initData } = useGetMiniAppInitData();
  const botLink = `https://t.me/${Environment.TelegramBotName}/${Environment.TelegramMiniAppName}?startapp=navToReferrals`;
  const goToMiniApp = () => window.open(botLink, '_self');

  const isInMiniApp = !!initData;

  const shareStory = () => {
    if (isInMiniApp) {
      window.Telegram.WebApp.shareToStory('https://mighty-storage.fra1.digitaloceanspaces.com/static/images/stories-template.png', {
        text: text,
        widget_link: { url, name: 'Mighty Academy' },
      });
    }
  };

  return { isInMiniApp, shareStory, goToMiniApp };
};

export const useGetReferralInfo = () => {
  const userData = useGetUserData();
  const referralCode = useMemo(() => userData?.referrals?.referralCode ?? '', []);

  const shareText = useReferralsShareText();

  const refLink = `${window.location.origin}/ref/${referralCode}`;
  const tgRefLink = `https://t.me/${Environment.TelegramBotName}/${Environment.TelegramMiniAppName}?startapp=refCode_${referralCode}`;

  return { referralCode, refLink, tgRefLink, shareText };
};

export const useReferralsShareText = () => {
  const { displayedName } = useSelector(getAppSettings);

  return `Join ${displayedName}, complete a lesson and get a Nature Chest!`;
};
