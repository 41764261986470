import { useEffect, useMemo, useState } from 'react';
import {
  IFormattedLeaderboardUser,
  useLazyGetLeaderboardUsersBySpaceQuery,
  useLazyGetLeaderboardUsersQuery,
  useLazyGetSpecialLeaderboardUsersBySpaceQuery,
} from 'store/leaderboard';
import { useGetStoreUser } from './useGetStoreUser';
import { FRIEND_STATUS } from 'app/constants';
import { useGetSpaceByNameQuery } from 'store';
import { useDebounce } from './useDebounce';

type Props = {
  isMonthly?: boolean;
  isFriends?: boolean;
  spaceName?: string;
  filter?: string;
};

interface IState {
  total: IFormattedLeaderboardUser[];
  topMonth: IFormattedLeaderboardUser[];
}

export const useGetLeaderboardUsers = ({ isMonthly, isFriends }: Props) => {
  const { friends, _id: userId } = useGetStoreUser();
  const [getLeaderboard, { isFetching }] = useLazyGetLeaderboardUsersQuery();
  const [users, setUsers] = useState<IState>({ topMonth: [], total: [] });

  const friendsIds = useMemo(
    () => friends.filter((f) => f.status === FRIEND_STATUS.FRIENDS).map((f) => (f.recipient._id === userId ? f.requester : f.recipient._id)),
    [friends, userId]
  );

  const usersToExport = useMemo(() => {
    const usersByTime = isMonthly ? [...users.topMonth] : [...users.total];
    let usersProcessed = usersByTime;

    if (isFriends) {
      usersProcessed = usersProcessed.filter((i) => friendsIds.includes(i.extId));
      const user = usersByTime.find((u) => u.extId === userId);
      if (user) usersProcessed.push(user);
    }

    return usersProcessed.sort((a, b) => b.balance - a.balance).map((el, i) => ({ ...el, place: i + 1 }));
  }, [users, isMonthly, isFriends, friendsIds, userId]);

  useEffect(() => {
    getLeaderboard()
      .unwrap()
      .then((res) => {
        const usersData = JSON.parse(res.jsonData) as IState;
        usersData.total = usersData.total.sort((a, b) => b.balance - a.balance).map((el, i) => ({ ...el, place: i + 1 }));
        usersData.topMonth = usersData.topMonth.sort((a, b) => b.balance - a.balance).map((el, i) => ({ ...el, place: i + 1 }));
        setUsers(usersData);
      })
      .catch(console.error);
  }, [getLeaderboard]);

  return { users: usersToExport, isFetching };
};

export const useGetLeaderboardUsersBySpace = ({ isMonthly, isFriends, spaceName, filter }: Props) => {
  const { friends, _id: userId } = useGetStoreUser();
  const [getLeaderboard, { isFetching: isFetchingLeaderboard }] = useLazyGetLeaderboardUsersBySpaceQuery();
  const [getSpecialLeaderboard, { isFetching: isFetchingSpecialLeaderboard }] = useLazyGetSpecialLeaderboardUsersBySpaceQuery();
  const { isFetching: isFetchingSpace, data: spaceData } = useGetSpaceByNameQuery(spaceName ?? '', { skip: !spaceName });
  const [users, setUsers] = useState<IState>({ topMonth: [], total: [] });
  const debounce = useDebounce();

  const friendsIds = useMemo(
    () =>
      !!friends && !!userId
        ? friends.filter((f) => f.status === FRIEND_STATUS.FRIENDS).map((f) => (f.recipient._id === userId ? f.requester : f.recipient._id))
        : [],
    [friends, userId]
  );

  const usersToExport = useMemo(() => {
    const usersByTime = isMonthly ? [...users.topMonth] : [...users.total];
    let usersProcessed = usersByTime;

    if (isFriends) {
      usersProcessed = usersProcessed.filter((i) => friendsIds.includes(i.extId));
      const user = usersByTime.find((u) => u.extId === userId);
      if (user) usersProcessed.push(user);
    }

    return usersProcessed.sort((a, b) => b.balance - a.balance).map((el, i) => ({ ...el, place: i + 1 }));
  }, [users, isMonthly, isFriends, friendsIds, userId]);

  useEffect(() => {
    debounce(async () => {
      if (spaceName) {
        if (!spaceData || isFetchingSpace) return;
        setUsers({ topMonth: [], total: [] });
        const { defaultLeaderboard } = spaceData;

        if (defaultLeaderboard && filter === defaultLeaderboard) {
          await getSpecialLeaderboard({ spaceName, token: defaultLeaderboard })
            .unwrap()
            .then((res) => {
              const total: IFormattedLeaderboardUser[] = [...res.items]
                .sort((a, b) => b.exp - a.exp)
                .map((el, i) => ({ avatar: el.userId.avatar, balance: el.exp, extId: el.userId._id, nickname: el.userId.nickname, place: i + 1 }));
              const topMonth: IFormattedLeaderboardUser[] = [...res.items]
                .sort((a, b) => b.exp - a.exp)
                .map((el, i) => ({ avatar: el.userId.avatar, balance: el.exp, extId: el.userId._id, nickname: el.userId.nickname, place: i + 1 }));
              setUsers({ total, topMonth });
            })
            .catch(console.error);
        } else {
          await getLeaderboard(spaceName)
            .unwrap()
            .then((res) => {
              const total: IFormattedLeaderboardUser[] = [...res.total]
                .sort((a, b) => b.exp - a.exp)
                .map((el, i) => ({ avatar: el.userId.avatar, balance: el.exp, extId: el.userId._id, nickname: el.userId.nickname, place: i + 1 }));
              const topMonth: IFormattedLeaderboardUser[] = [...res.topMonth]
                .sort((a, b) => b.exp - a.exp)
                .map((el, i) => ({ avatar: el.userId.avatar, balance: el.exp, extId: el.userId._id, nickname: el.userId.nickname, place: i + 1 }));
              setUsers({ total, topMonth });
            })
            .catch(console.error);
        }
      }
    }, 700)();
  }, [getLeaderboard, getSpecialLeaderboard, spaceName, filter, spaceData, isFetchingSpace]);

  return { users: usersToExport, isFetching: isFetchingLeaderboard || isFetchingSpecialLeaderboard || isFetchingSpace };
};
