import { EBlockchain, GRADES } from 'app/constants';
import { TGrade } from './grade';

export interface IUserExp {
  id: number;
  extId: string;
  email: string;
  name: string;
  energy: number;
  energyPerHour: number;
  lastEnergyDrain: string;
  createdAt: string;
  updatedAt: string;
  limit: IExpLimit;
  balance: IExpBalance;
  items: IUserItem[];
}

export interface IItem {
  id: number;
  quantity: number | null;
  price: number | null;
  createdAt: string;
  updatedAt: string;
  item: IDatabaseItem;
}

export interface IShopItem extends IItem {
  price: number;
  userBalance?: number;
}

export interface IUserItem extends IItem {
  quantity: number;
}

export enum EItemFungibleType {
  SmallEnergyStone = 'small-energy-stone',
  LargeEnergyStone = 'large-energy-stone',

  SmallEnergyPotion = 'small-energy-potion',
  LargeEnergyPotion = 'large-energy-potion',

  ExpPotion = 'exp-potion',
  EnhancedExpPotion = 'enhanced-exp-potion',
  Amethyst = 'amethyst',
  YellowDiamond = 'yellow-diamond',

  ScholarCoin = 'scholar-coin',
  EmperorBadge = 'emperor-badge',
  EmperorKey = 'emperor-key',
  EmperorCrown = 'emperor-crown',

  EarlySupporterBadge = 'early-supporter-badge',

  RedLuminaryPowder = 'red-luminary-powder',
  GreenLuminaryPowder = 'green-luminary-powder',
  YellowLuminaryPowder = 'yellow-luminary-powder',
  PurpleLuminaryPowder = 'purple-luminary-powder',
  GrayLuminaryPowder = 'gray-luminary-powder',
  BlueLuminaryPowder = 'blue-luminary-powder',

  RedInkBottle = 'red-ink-bottle',
  GreenInkBottle = 'green-ink-bottle',
  YellowInkBottle = 'yellow-ink-bottle',
  PurpleInkBottle = 'purple-ink-bottle',
  GrayInkBottle = 'gray-ink-bottle',
  BlueInkBottle = 'blue-ink-bottle',

  NatureChest = 'nature-chest',
  VioletChest = 'violet-chest',
  AquaChest = 'aqua-chest',
  // StoneChest = 'stone-chest',
  RoyalChest = 'royal-chest',
  DivineChest = 'divine-chest',

  NaturePurse = 'nature-purse',
  VioletPurse = 'violet-purse',
  AquaPurse = 'aqua-purse',
  RoyalPurse = 'royal-purse',

  EnergyShard = 'energy-shard',
  LifeShard = 'life-shard',
  LuckShard = 'luck-shard',
  ExperienceEssenceShard = 'experience-essence-shard',
  UnknownLiquid = 'unknown-liquid',

  ThePlagueNFT = 'the-plague-nft',
  AzukiBean = 'azuki-bean',
  SappySealsNFT = 'sappy-seals-nft',
  USDC10 = '10-usdc',
  USDC20 = '20-usdc',
  USDC30 = '30-usdc',
  USDC50 = '50-usdc',
  USDC100 = '100-usdc',
  USDC300 = '300-usdc',
}
export enum EItemFungiblePremiumType {
  PremiumOneDay = 'scholar-coin',
  PremiumOneWeek = 'emperor-badge',
  PremiumOneMonth = 'emperor-key',
  PremiumOneYear = 'emperor-crown',
}
export enum EChestFungibleType {
  NatureChest = 'nature-chest',
  VioletChest = 'violet-chest',
  AquaChest = 'aqua-chest',
  // StoneChest = 'stone-chest',
  RoyalChest = 'royal-chest',
  DivineChest = 'divine-chest',
}

export enum EServiceEffect {
  ENERGY = 'energy',
  NOENERGY = 'no-energy',
  NOPREMIUM = 'no-premium',
}
export type TItemFungiblePremiumType = `${EItemFungiblePremiumType}`;

export type TItemFungibleTypeForEffects = `${EItemFungibleType}` | `${EServiceEffect}` | `${EItemFungiblePremiumType}`;

export type TItemFungibleType = TItemFungibleTypeForEffects | `${EChestFungibleType}` | null;

export interface IDatabaseItem {
  id: number;
  name: string;
  image: string;
  url: string;
  imageThumb: string;
  description: string;
  type: 'fungible' | 'non-fungible' | 'digital';
  fungibleType: TItemFungibleType | EItemFungibleType | null;
  createdAt: string;
  updatedAt: string;
  grade: TGrade | GRADES;
  blockchain: EBlockchain | null;
  contract: string | null;
}

export interface IDatabaseItemQuantified extends IDatabaseItem {
  quantity: number;
}

export interface IUserExpBalance {
  id: number;
  amount: number;
  createdAt: string;
  updatedAt: string;
}

export interface IUserExpLimit {
  id: number;
  maxEnergy: number;
  multiplier: number;
  createdAt: string;
  updatedAt: string;
  effects: any[];
}

export interface IExpPartner {
  id: number;
  maxTokens: number;
  tokenPerUser: number;
  reason: string;
  status: IExpStatus;
  createdAt: string;
  updatedAt: string;
}

export interface IExpBalance {
  id: number;
  amount: number;
  createdAt: string;
  updatedAt: string;
}

export interface IExpLimit {
  id: number;
  maxEnergy: number;
  expMulti: number;
  regenMulti: number;
  createdAt: string;
  updatedAt: string;
  effects: IExpEffect[];
}

export interface IExpEffect {
  id: number;
  name: string;
  description?: string;
  lore?: string;
  url?: string;
  reason: string;
  regenMulti: number;
  expMulti: number;
  createdAt: string;
  updatedAt: string;
  expiredAt: string;
  itemFungibleType: TItemFungibleTypeForEffects;
}

export type IExpStatus = 'active' | 'inactive';

export type IDigitalSecret = {
  activated: boolean;
  code: string;
  createdAt: string;
  description: string;
  id: number;
  updatedAt: string;
  url: string;
};
