export enum EBlockchain {
  BNB = 'bnb',
  Ethereum = 'eth',
  Polygon = 'polygon',
  TRON = 'tron',
  EOS = 'eos',
  Avalanche = 'avalanche',
  Solana = 'solana',
}
export const blockchainNativeName = {
  [EBlockchain.BNB]: 'Binance Smart Chain',
  [EBlockchain.Ethereum]: 'Ethereum',
  [EBlockchain.Polygon]: 'Polygon',
  [EBlockchain.TRON]: 'TRON',
  [EBlockchain.EOS]: 'EOS',
  [EBlockchain.Avalanche]: 'Avalanche',
  [EBlockchain.Solana]: 'Solana',
};
