import { GRADES } from 'app/constants';

export enum EEffectsListItemBadgeType {
  TIMER = 'expires_in',
  EXP = 'exp_recieved',
  ENERGY = 'energy_pool',
  ENERGY_RECOVERY = 'energy_recovery',
  ENERGY_CAP = 'max_spending',
}
export interface IEffectsListItemBadge {
  label: string | number;
  type: EEffectsListItemBadgeType;
  grade?: GRADES | null;
}
