import { EBlockchain } from 'app/constants';

export const iconsUrls = {
  [EBlockchain.Avalanche]: 'https://cryptologos.cc/logos/avalanche-avax-logo.svg?v=029',
  [EBlockchain.EOS]: 'https://cryptologos.cc/logos/eos-eos-logo.svg?v=029',
  [EBlockchain.BNB]: 'https://cryptologos.cc/logos/bnb-bnb-logo.svg?v=029',
  [EBlockchain.Ethereum]: 'https://cryptologos.cc/logos/ethereum-eth-logo.svg?v=029',
  [EBlockchain.Polygon]: 'https://cryptologos.cc/logos/polygon-matic-logo.svg?v=029',
  [EBlockchain.Solana]: 'https://cryptologos.cc/logos/solana-sol-logo.svg?v=029',
  [EBlockchain.TRON]: 'https://cryptologos.cc/logos/tron-trx-logo.svg?v=029',
};
