import { STATIC_HOST_PATH } from 'app/constants/path';

interface CheckImageResult {
  path: string;
  status: 'ok' | 'error';
}

export default function checkImage(path: string): Promise<CheckImageResult> {
  const pathModified = path ? STATIC_HOST_PATH + '/images-proxy?url=' + path : '';

  return new Promise((resolve) => {
    const img = new Image();
    img.onload = () => resolve({ path: pathModified, status: 'ok' });
    img.onerror = () => resolve({ path: pathModified, status: 'error' });

    img.src = pathModified;
  });
}
