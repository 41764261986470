import { useMemo } from 'react';
import { IRewardMinimal } from 'store';
import { useGetItems } from './useGetStoreGeneralData';
import { IDatabaseItemQuantified } from 'interface';

type Props = {
  rewards: IRewardMinimal[];
};

/**
 * Get IDatabaseItemQuantified array from IRewardMinimal[], with counted rewards quantity
 */

export const useReduceClaimedRewards = ({ rewards }: Props) => {
  const items = useGetItems();
  const formatedRewards = useMemo(() => {
    const rews = Object.entries(
      rewards.reduce((acc, el) => {
        // check total quantity for IRewardMinimal[]
        if (acc[el.id]) {
          acc[el.id] += el.quantity;
        } else {
          acc[el.id] = el.quantity;
        }
        return acc;
      }, {} as { [key: string]: number })
    )
      .map((el) => {
        // make IDatabaseItemQuantified[] with claimed rewards
        let item = items.find((i) => i.id === +el[0]);
        if (!!item) {
          return { quantity: el[1], ...item };
        } else {
          return null;
        }
      })
      .filter((el) => !!el);

    return rews as IDatabaseItemQuantified[];
  }, [rewards, items]);

  return formatedRewards;
};
