import clsx from 'clsx';
import { FC, memo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { getAppSettings } from 'store';

import { IPageLoader } from './model';
import { usePageLoader } from './hooks';

import styles from './styles.module.scss';

export const PageLoader: FC<IPageLoader> = memo(({ isLoading, setAnimateIsExit, saveMemory = false, isPagePreload = false, isFixed = true }) => {
  const { globalLoaderLink, displayedName } = useSelector(getAppSettings);

  const { animationComplete, firstFrameIsExit, currentVariantWrap, currentVariantStart, handleOnAnimateComplete } = usePageLoader({
    isLoading,
    setAnimateIsExit,
    saveMemory,
  });

  if (animationComplete || displayedName === 'Mighty.') {
    return <></>;
  }

  return (
    <motion.div
      custom={isLoading}
      className={clsx(styles.wrap, isPagePreload ? styles.isPagePreload : '', isFixed)}
      variants={currentVariantWrap}
      animate={'animate'}
      exit={'exit'}
      onAnimationComplete={handleOnAnimateComplete}
    >
      <AnimatePresence>
        {isLoading && (
          <motion.div
            custom={firstFrameIsExit}
            className={clsx(styles.start)}
            variants={currentVariantStart}
            initial={'initial'}
            animate={'animate'}
            exit={'exit'}
          >
            <img rel="preload" src={globalLoaderLink} alt={''} />
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
});
