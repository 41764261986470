import { useNavigate } from 'react-router-dom';
import { useGetMiniAppInitData } from './getInitData';
import { useEffect } from 'react';
import { useGetIsAuth } from 'hooks';
import { useGetReferralInfo, useMiniAppStoryMethods } from 'components/pages/referrals/screen/utils';

/**
 * Navigate unauth user to auth via ref route, if refCode exists.
 * Navigates to shop if startParam equals to 'navToShop' (made for test, left here as example).
 * No more functionality for now.
 */

export const useHandleMiniAppStartParam = () => {
  const { startParam, initData } = useGetMiniAppInitData();
  const { tgRefLink, shareText } = useGetReferralInfo();
  const { shareStory, isInMiniApp } = useMiniAppStoryMethods(tgRefLink, shareText);
  const isAuth = useGetIsAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (startParam && isInMiniApp) {
      if (startParam.includes('refCode_') && !isAuth) {
        const refCode = startParam.replace('refCode_', '');
        navigate(`/ref/${refCode}`);
        return;
      }
      if (startParam.includes('navToShop')) {
        navigate('/shop');
        return;
      }
      if (startParam.includes('navToReferrals') && isAuth) {
        shareStory();
        return;
      }
    }
  }, [startParam, isAuth, initData]);
};
