import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      padding: 16px 20px 18px 20px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      min-width: var(--toast-width);
      max-width: var(--toast-width);
      border: 1px solid transparent;
      background: #23273a;

      &.correct {
        --color: #26a17b;
        border-color: var(--color);
        color: var(--color);
        background: #294050;
        &.light {
          background: #c8e9cc;
        }
      }
      &.incorrect {
        --color: #f14f63;
        border-color: var(--color);
        color: var(--color);
        background: #46334c;
        &.light {
          background: #f9b7b7;
        }

        & svg {
          scale: 1.3;
        }
      }
      &.declined {
        --color: #ffbf66;
        border-color: var(--color);
        color: var(--color);
        background: #48444c;
        &.light {
          background: #f9fac5;
        }
      }
    `
  ),
  TextWrap = styled.span(
    () => css`
      display: inline-flex;
      flex-direction: column;
    `
  ),
  Text = styled.span(
    () => css`
      margin-left: 16px;
      color: var(--font-color-strong);
    `
  ),
  Subtext = styled.span(
    () => css`
      margin-left: 16px;
      font-size: 16;
      color: var(--font-color-strong);
      opacity: 0.7;
    `
  ),
  Icon = styled.div(
    () => css`
      height: 38px;
      width: 38px;
      min-height: 38px;
      min-width: 38px;
      color: var(--basic-dark);

      & > svg {
        width: 100%;
        height: 100%;
        color: var(--color);
      }
    `
  );
