import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'store';

interface IInitialState {
  selectedText: string;
  idText: string;
}

const initialState: IInitialState = {
  selectedText: '',
  idText: '',
};

export const commentsSlice = createSlice({
  name: 'comments',
  initialState,
  reducers: {
    setSelectedText: (state, action: PayloadAction<IInitialState>) => {
      state.selectedText = action.payload.selectedText;
      state.idText = action.payload.idText;
    },
  },
});

export const { setSelectedText } = commentsSlice.actions;
export const getSelectedText = (state: RootStateType) => state.comments.selectedText;
export const getIdText = (state: RootStateType) => state.comments.idText;

export default commentsSlice.reducer;
