import { LOCAL_STORAGE_KEYS } from 'app/constants';

class AverageColorsCache {
  private data: Record<string, string> = {};
  private storageKey: LOCAL_STORAGE_KEYS;

  constructor() {
    this.storageKey = LOCAL_STORAGE_KEYS.AVERAGE_COLOR_CACHE;
    this.data = JSON.parse(localStorage.getItem(this.storageKey) || '{}');
  }

  get(url: string) {
    return this.data[url];
  }

  set(url: string, color: string) {
    if (url) {
      this.data[url] = color;
      localStorage.setItem(this.storageKey, JSON.stringify(this.data));
    }
  }

  has(url: string) {
    return this.data[url] !== undefined;
  }

  clean() {
    localStorage.removeItem(this.storageKey);
    this.data = {};
  }
}

export const averageColorsCache = new AverageColorsCache();
