import { useEffect, useRef, useCallback } from 'react';

export const useObserver = ({ handler }: { handler: (isIntersecting: boolean) => void }) => {
  // Create mutable container for both observer and element
  const state = useRef<{
    observer: IntersectionObserver | null;
    element: HTMLElement | null;
  }>({
    observer: null,
    element: null,
  });

  // Setup observer only once
  useEffect(() => {
    // Create observer
    state.current.observer = new IntersectionObserver(
      (entries) => {
        const [entry] = entries;
        handler(entry.isIntersecting);
      },
      {
        root: null,
        rootMargin: '50px',
        threshold: 0,
      }
    );

    // Initial observe if element exists
    if (state.current.element) {
      state.current.observer.observe(state.current.element);
    }

    // Cleanup
    return () => {
      if (state.current.observer) {
        state.current.observer.disconnect();
        state.current.observer = null;
      }
    };
  }, [handler]);

  // Create ref callback
  const setRef = useCallback((element: HTMLElement | null) => {
    // Clean up old observation
    if (state.current.observer && state.current.element) {
      state.current.observer.unobserve(state.current.element);
    }

    // Save new element
    state.current.element = element;

    // Start new observation
    if (element && state.current.observer) {
      state.current.observer.observe(element);
    }
  }, []);

  return setRef;
};
