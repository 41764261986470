import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'store';

interface IInitialState {
  peopleOnline: string[];
}

const initialState: IInitialState = {
  peopleOnline: [],
};

export const websocketSlice = createSlice({
  name: 'websocket',
  initialState,
  reducers: {
    setPeopleOnline: (state, action: PayloadAction<string[]>) => {
      state.peopleOnline = action.payload;
    },
  },
});

export const { setPeopleOnline } = websocketSlice.actions;

const getPeopleOnlineForSelector = (state: RootStateType) => state.websocket.peopleOnline;

// Мемоизированные селекторы
export const getPeopleOnline = createSelector(getPeopleOnlineForSelector, (peopleOnline) => peopleOnline);

export default websocketSlice.reducer;
