import styled, { css } from 'styled-components';

export const Container = styled.span(
    () => css`
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 25px;

      @media screen and (max-width: 428px) {
        width: 45px;
        & {
          font-size: 28px;
        }
      }
    `
  ),
  Title = styled.p(
    () => css`
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 1;
      color: var(--font-color-strong);
      margin-top: 7px;
      margin-left: 6px;
    `
  ),
  Image = styled.img(
    () => css`
      object-fit: contain;
      aspect-ratio: 1/1;
      max-width: 100%;
      max-height: 100%;
    `
  );
