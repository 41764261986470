import { useCallback, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import RequestToast from 'shared/ui/toasts/requestToast/request-toast';
import { setPeopleOnline, useLazyGetMyPartsQuery, IRewardMinimal } from 'store';
import { dispatch } from './useDispatch';
import Message from 'components/library/messages/Message/Message';
import { EVENTS } from 'app/constants';
import { getItemByKey } from 'utils';
import Answer from 'components/library/messages/Answer';
import { useGetUserData } from './useGetUserData';
import { useGetIsInit, useGetItems } from './useGetStoreGeneralData';

const useWebSocket = () => {
  const isInit = useGetIsInit();
  const items = useGetItems();
  const [getUserPartial] = useLazyGetMyPartsQuery();
  const $reconnectCount = useRef(0);
  const $reconnectTimeout = useRef<null | number>(null);
  const [msg, setMessage] = useState(null);
  const [socket, setSocket] = useState<WebSocket | null>(null);
  const { _id: currentUserId } = useGetUserData();

  const connect = useCallback(() => {
    if (currentUserId) {
      const url = process.env.REACT_APP_WEBSOCKET_URL;
      if (url) {
        const newSocket = new WebSocket(url);

        if (newSocket.OPEN) {
          setSocket(newSocket);
        }
      }
    }
  }, [currentUserId]);

  const handleSocketOpen = useCallback(() => {
    if (isInit && socket?.readyState === WebSocket.OPEN) {
      const message = { event: 'connection', id: currentUserId };
      // console.log('websocket is up');
      socket.send(JSON.stringify(message));
    }
  }, [currentUserId, isInit, socket]);

  const handleReceiveMessage = useCallback(
    async (event: MessageEvent) => {
      try {
        const message = JSON.parse(event.data);
        console.log({ message });

        // console.log(message);
        if (message.peopleOnline) {
          dispatch(setPeopleOnline(message.peopleOnline));
        }
        if (message.event === EVENTS.USERFOLLOWED) {
          toast.info(<RequestToast {...message.requester} type="friend" />, { autoClose: false, closeButton: false });
        }
        if (message.event === EVENTS.USERCHALLENGED) {
          toast.info(<RequestToast {...message.challenger} battleId={message.battleId} type="battle" />, { autoClose: false, closeButton: false });
        }
        if (message.event === EVENTS.USERREWARDED) {
          message?.rewards?.forEach((reward: IRewardMinimal) => {
            const item = getItemByKey('id', reward.id, items);
            if (item) {
              toast.info(<Message amount={reward.quantity} itemId={item.id} status="complete" type="receive" />, { autoClose: false });
            }
          });
        }
        if (message.event === EVENTS.USERWON) {
          toast.success(<Answer label="Congrats!" subtext={`You have defeated ${message.nickname} and earned some items!`} type="correct" />);
          await getUserPartial({ props: ['exp', 'stats', 'cooldowns'] });
        }
        if (message.event === EVENTS.USERLOST) {
          toast.error(<Answer label="You loose!" subtext={`${message.nickname} have defeated you.`} type="incorrect" />);
          await getUserPartial({ props: ['exp', 'stats', 'cooldowns'] });
        }
        if (message.event === EVENTS.PREMIUMACTIVATED) {
          await getUserPartial({ props: ['exp'] });
        }
        setMessage(message);
      } catch (ex: any) {
        console.error(ex?.message);
      }
    },
    [items, getUserPartial]
  );

  const handleSendMessage = useCallback(
    (event: EVENTS, message: Record<string, any>) => {
      if (socket?.OPEN) {
        socket.send(
          JSON.stringify({
            event,
            ...message,
          })
        );
      }
    },
    [socket]
  );

  const handleSocketClose = useCallback(() => {
    if ($reconnectTimeout.current) {
      window.clearTimeout($reconnectTimeout.current);
    }

    if ($reconnectCount.current >= 5) {
      return;
    }
    $reconnectTimeout.current = window.setTimeout(() => {
      // console.log('connect from timeout');
      $reconnectCount.current = $reconnectCount.current + 1;
      connect();
    }, 5000);
  }, [connect]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    socket.onmessage = handleReceiveMessage;
    socket.onopen = handleSocketOpen;
    socket.onclose = handleSocketClose;
    socket.onerror = handleSocketClose;

    return () => {
      socket.onmessage = null;
      socket.onopen = null;
      socket.onclose = null;
    };
  }, [socket, handleSocketClose, handleReceiveMessage, handleSocketOpen]);

  useEffect(() => {
    connect();
    return () => socket?.close();
  }, [currentUserId, connect]);

  return { message: msg, socket, sendMessage: handleSendMessage };
};

export default useWebSocket;
