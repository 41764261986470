import { createApi } from '@reduxjs/toolkit/query/react';
import { IMeditationItem, IUserMeditations } from 'interface';
import { customFetchBase } from 'store/utils';
import { ICompleteMeditationBody, ICompleteMeditationResponce } from './meditation.types';

export const meditationApi = createApi({
  reducerPath: 'meditationApi',
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    getMeditation: builder.query<IMeditationItem[], void>({
      query: () => {
        return {
          url: '/meditation-item',
        };
      },
    }),
    getMeditationById: builder.query<IMeditationItem, string>({
      query: (id) => {
        return {
          url: `/meditation-item/${id}`,
        };
      },
    }),
    favourMeditation: builder.mutation<IUserMeditations, ICompleteMeditationBody>({
      query: (data) => ({
        url: `/meditation/favour`,
        method: 'PUT',
        body: data,
      }),
    }),
    completeMeditation: builder.mutation<ICompleteMeditationResponce, ICompleteMeditationBody>({
      query: (data) => ({
        url: `/meditation/complete`,
        method: 'PUT',
        body: data,
      }),
    }),
  }),
});

export const { useGetMeditationQuery, useLazyGetMeditationQuery, useCompleteMeditationMutation, useFavourMeditationMutation } = meditationApi;
