import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from 'store/utils';

export const tasksCheckApi = createApi({
  reducerPath: 'tasksCheckApi',
  // refetchOnFocus: false,
  refetchOnReconnect: true,
  baseQuery: customFetchBase,
  endpoints: (builder) => ({
    checkTONBridgeTask: builder.query<{ status: 'done' | 'waiting' }, 'ETH' | 'BSC'>({
      query: (network) => {
        return {
          url: `/tasks/bridge?network=${network}`,
        };
      },
      keepUnusedDataFor: 100,
    }),
    checkTONBalanceTask: builder.query<{ status: 'done' | 'waiting' }, void>({
      query: () => {
        return {
          url: `/tasks/balance`,
        };
      },
      keepUnusedDataFor: 100,
    }),
  }),
});
export const { useLazyCheckTONBridgeTaskQuery, useLazyCheckTONBalanceTaskQuery } = tasksCheckApi;
