import { useEffect } from 'react';

const useSendLoadedMessageToIframe = (isLoaded: boolean) => {
  useEffect(() => {
    if (isLoaded && window.top) {
      window.top.postMessage('loaded', '*');
    }
  }, [isLoaded]);
};

export default useSendLoadedMessageToIframe;
