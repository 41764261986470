import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from '../utils';
import { IComment, ICommentCountResponse, ICommentResponse, IPostComment } from 'interface';
import { IRewardMinimal } from 'store';

export const commentsApi = createApi({
  reducerPath: 'commentsApi',
  baseQuery: customFetchBase,
  tagTypes: ['Comments'],
  endpoints: (builder) => ({
    getComments: builder.query<ICommentResponse, { slideId: string; queryString: string }>({
      query: ({ slideId, queryString }) => `/comment/${slideId}${queryString}`,
      providesTags: (result, error, arg) => {
        return [{ type: 'Comments', id: arg.slideId }];
      },
    }),
    getCountComments: builder.query<ICommentCountResponse, { slideId: string }>({
      query: ({ slideId }) => `/comment/count/${slideId}`,
    }),
    postComment: builder.mutation<IComment, IPostComment>({
      query: (data: IPostComment) => ({
        url: `/comment`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error, arg) => {
        return [{ type: 'Comments', id: arg.slideId }];
      },
    }),
    rewardUserAndArchiveComment: builder.mutation<void, { commentId: string; rewards?: IRewardMinimal[]; slideId: string }>({
      query: (data) => ({
        url: `/comment/archive`,
        method: 'PUT',
        body: data,
      }),
    }),

    // NEW METHODS

    getCommentsBySlide: builder.query<{ items: IComment[] }, string>({
      query: (id) => ({
        url: `/comment/pending/by-slide-id/${id}`,
      }),
      providesTags: ['Comments'],
    }),
    getCommentsByChapter: builder.query<{ items: IComment[] }, string>({
      query: (id) => ({
        url: `/comment/pending/by-chapter-id/${id}`,
      }),
      providesTags: ['Comments'],
    }),
    getCommentsByLesson: builder.query<{ items: IComment[] }, string>({
      query: (id) => ({
        url: `/comment/pending/by-lesson-id/${id}`,
      }),
      providesTags: ['Comments'],
    }),
    getCommentsByCourse: builder.query<{ items: IComment[] }, string>({
      query: (id) => ({
        url: `/comment/pending/by-course-id/${id}`,
      }),
      providesTags: ['Comments'],
    }),
  }),
});

export const {
  usePostCommentMutation,
  useGetCommentsQuery,
  useRewardUserAndArchiveCommentMutation,
  useGetCountCommentsQuery,
  useLazyGetCommentsByChapterQuery,
  useLazyGetCommentsByCourseQuery,
  useLazyGetCommentsByLessonQuery,
  useLazyGetCommentsBySlideQuery,
  useGetCommentsBySlideQuery,
} = commentsApi;
