import { RefObject, useState } from 'react';

interface IProps {
  src: string;
  ref: RefObject<HTMLElement>;
}

export const useLoadBackgroundImage = ({ src, ref }: IProps) => {
  const [isLoading, setIsLoading] = useState(true);

  const img = document.createElement('img');

  img.onload = () => {
    if (!ref.current) return;

    ref.current.style.backgroundImage = `url(${src})`;
    setIsLoading(false);
  };

  img.src = src;

  return { isLoading };
};
