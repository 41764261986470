import clsx from 'clsx';
import { useRemoveFriendMutation, useLazyGetBattleByIdQuery, useRejectBattleMutation } from 'store';
import { toast } from 'react-toastify';
import { useAddFriend } from 'hooks';

import styles from './styles.module.css';
import AvatarPic from 'components/library/utils/AvatarPic/avatar';
import { ICustomization } from 'interface';
import BasicButton from '../../basic-button/button';

import { ReactComponent as UserCheckedIcon } from 'assets/icons/user-checked.svg';
import { ReactComponent as UserErrorIcon } from 'assets/icons/user-error.svg';

interface IProps {
  avatar: string;
  nickname: string;
  _id: string;
  battleId?: string;
  type: 'friend' | 'battle';
  customization: ICustomization;
}

export default function RequestToast({ avatar = '', nickname = '', type = 'friend', _id = '', battleId = '', customization }: IProps) {
  const { addFriend, isLoading } = useAddFriend();
  const [removeFriend] = useRemoveFriendMutation();
  const [getBattleById] = useLazyGetBattleByIdQuery();
  const [rejectBattle] = useRejectBattleMutation();

  async function handleAddFriend() {
    await addFriend(_id).then(() => {
      toast.success(
        <div className={clsx(styles.container, styles.friend)}>
          <AvatarPic customization={customization} className={styles.avatar} nickname={nickname} avatar={avatar} isFramed={false} />
          <div className={styles.full}>
            <p className={styles.title}>
              <span style={{ color: `var(--${type}-color)` }} className={styles.nickname}>
                {nickname}
                <UserCheckedIcon />
              </span>
              <div className={styles.maxW}>has been added as a friend</div>
            </p>
            <div className={clsx(styles.buttons, styles.button)}>
              <BasicButton label={'Cancel'} />
            </div>
          </div>
        </div>,
        { autoClose: false, closeButton: false }
      );
    });
  }

  async function handleDeclineFriend() {
    try {
      const response = await removeFriend({ id: _id }).unwrap();
      if (response) {
        toast.success(
          <div className={clsx(styles.container, styles.friend, styles.remove)}>
            <AvatarPic customization={customization} className={styles.avatar} nickname={nickname} avatar={avatar} isFramed={false} />
            <div className={styles.full}>
              <p className={styles.title}>
                <span style={{ color: `var(--${type}-color)` }} className={styles.nickname}>
                  {nickname}
                  <UserErrorIcon />
                </span>
                <div className={styles.maxW}>request has been declined</div>
              </p>
              <div className={clsx(styles.buttons, styles.button)}>
                <BasicButton label={'Close'} />
              </div>
            </div>
          </div>,
          { autoClose: false, closeButton: false }
        );
      }
    } catch (ex) {
      console.log(ex);
      toast.error('Oops! Some error occured:/');
    }
  }

  async function handleAcceptChallenge() {
    try {
      const responce = await getBattleById(battleId).unwrap();
      if (responce?._id) {
        // navigate(`${ROUTES.FULLSCREEN}/${ROUTES.FULLSCREEN_MINDBATTLE}/${battleId}`, { replace: true });
      }
    } catch (ex) {
      console.log(ex);
      toast.error('Oops! Some error occured:/');
    }
  }

  async function handleRejectBattleChallenge() {
    try {
      await rejectBattle({ battleId }).unwrap();
    } catch (ex) {
      console.log(ex);
      toast.error('Oops! Some error occured:/');
    }
  }

  return (
    <div className={clsx(styles.container, styles[type])}>
      <AvatarPic customization={customization} className={styles.avatar} nickname={nickname} avatar={avatar} isFramed={false} />
      <div className={styles.body}>
        <p className={styles.title}>
          <span style={{ color: `var(--${type}-color)` }} className={styles.nickname}>
            {nickname}
          </span>
          <div>{`sent a ${type} request`}</div>
        </p>
        {type === 'friend' ? (
          <div className={styles.buttons}>
            <BasicButton label={'Accept'} variant={'cool'} onClick={handleAddFriend} isDisabled={isLoading} />
            <BasicButton label={'Skip'} onClick={handleDeclineFriend} />
          </div>
        ) : (
          <div className={styles.buttons}>
            <BasicButton label={'Accept'} variant={'cool'} onClick={handleAcceptChallenge} isDisabled={isLoading} />
            <BasicButton label={'Skip'} onClick={handleRejectBattleChallenge} />
          </div>
        )}
      </div>
    </div>
  );
}
