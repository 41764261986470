import { IShopItem } from 'interface';
import { useEffect, useState } from 'react';
import { useLazyFetchShopQuery } from 'store';

type Props = {
  page?: number;
  limit?: number;
};

export const useGetShopItems = ({ page, limit }: Props) => {
  const [getItems, { data }] = useLazyFetchShopQuery();
  const [items, setItems] = useState<IShopItem[]>(data && data?.length > 0 ? data : []);

  const getMore = async ({ nextPage = page, nextLimit = limit }: { nextPage?: number; nextLimit?: number }) =>
    getItems({ page: nextPage, limit: nextLimit });

  useEffect(() => {
    (async () =>
      await getItems({ page, limit })
        .unwrap()
        .then((res) => res && setItems(res)))();
  }, [page, limit, getItems]);

  return { items, getMore };
};
