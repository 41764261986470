import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'store';
import { authApi } from './auth.api';
import { profileApi } from '../profile/profile.api';

interface IInitialState {
  isAuth: boolean;
}

const initialState: IInitialState = {
  isAuth: false,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(profileApi.endpoints.getUserData.matchFulfilled, (state, _) => {
      state.isAuth = true;
    });
    builder.addMatcher(authApi.endpoints.refreshToken.matchFulfilled, (state, _) => {
      state.isAuth = true;
    });

    builder.addMatcher(authApi.endpoints.logOut.matchFulfilled, (state, _) => {
      state.isAuth = false;
    });
  },
});

export const { setIsAuth } = authSlice.actions;

export const getIsAuth = (state: RootStateType) => state?.auth?.isAuth ?? false;

export default authSlice.reducer;
