import { getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';
import { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './styles.module.css';
import MightyLoaderMini from '../loader/mini/MightyLoaderMini';

interface IProps {
  className?: string;
  variant?: 'primary' | 'cool' | 'outline' | 'gold' | 'white';
  label: string;
  icon?: any;
  isDisabled?: boolean;
  isLabelFirst?: boolean;
  size?: 'md' | 'lg' | 'sm';
  fullWidth?: boolean;
  onClick?: () => any;
  style?: React.CSSProperties;
  type?: 'button' | 'submit';
  form?: string;
  loading?: boolean;
  loaderIcon?: ReactNode;
}

export default function BasicButton({
  className = '',
  isLabelFirst = false,
  variant = 'primary',
  label = '',
  icon,
  isDisabled,
  onClick,
  size = 'md',
  fullWidth = false,
  style,
  type = 'button',
  form,
  loading = false,
  loaderIcon,
}: IProps) {
  const theme = useSelector(getCurrentTheme);

  return (
    <button
      type={type}
      form={form}
      className={clsx(styles.container, styles[theme], {
        [className]: !!className,
        [styles.primary]: variant === 'primary',
        [styles.cool]: variant === 'cool',
        [styles.outline]: variant === 'outline',
        [styles.size_lg]: size === 'lg',
        [styles.size_md]: size === 'md',
        [styles.size_sm]: size === 'sm',
        [styles.fullWidth]: fullWidth,
        [styles.gold]: variant === 'gold',
        [styles.white]: variant === 'white',
        [styles.loading]: loading,
      })}
      onClick={onClick}
      disabled={isDisabled}
      style={style}
    >
      {loading ? (
        loaderIcon ? (
          loaderIcon
        ) : (
          <MightyLoaderMini className={styles.loader} />
        )
      ) : (
        <>
          {isLabelFirst ? (
            <>
              {label}
              {icon}
            </>
          ) : (
            <>
              {icon}
              {label}
            </>
          )}
        </>
      )}
    </button>
  );
}
