import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISpace, ISpaceCustomization } from './types';
import { spacesApi } from './spaces.api';
import { RootStateType } from 'store/store';

const initialState: Partial<ISpaceCustomization> = {};

const spaceCustomizationSlice = createSlice({
  name: 'spaceCustomizationSlice',
  initialState,
  reducers: {
    updateSpaceCustomization: (state, { payload }: PayloadAction<Partial<ISpaceCustomization>>) => {
      return { ...state, payload };
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(spacesApi.endpoints.getSpaceByName.matchFulfilled, (_, { payload }: PayloadAction<ISpace>) => {
      if (payload.customization) {
        return payload.customization;
      }
      return initialState;
    });
  },
});

const _getSpaceCustomization = (state: RootStateType) => state.spaceCustomization;
export const getSpaceCustomization = createSelector(_getSpaceCustomization, (customization) => customization);

export const { updateSpaceCustomization } = spaceCustomizationSlice.actions;
export default spaceCustomizationSlice.reducer;
