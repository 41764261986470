import { createContext, FC, useContext, useState } from 'react';

type TAppUiContextProps = {
  bodyWithControls: boolean;
  setBodyWithControls: (bodyWithControls: boolean) => void;
};

export const AppUiContext = createContext<TAppUiContextProps>(null as any);

export const AppUiContextProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [bodyWithControls, setBodyWithControls] = useState(true);

  return <AppUiContext.Provider value={{ bodyWithControls, setBodyWithControls }}>{children}</AppUiContext.Provider>;
};

export const useAppUiContext = () => {
  const { bodyWithControls, setBodyWithControls } = useContext(AppUiContext);

  return { bodyWithControls, setBodyWithControls };
};
