import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

const baseQuery = fetchBaseQuery({
  baseUrl: 'https://ipapi.co',
});

// an api to call third party api
export const geolocationApi = createApi({
  reducerPath: 'geolocationApi',
  baseQuery: baseQuery,
  endpoints: (builder) => ({
    getGeolocationData: builder.query<any, { format?: 'json' | 'yaml' }>({
      query: ({ format = 'json' }) => ({ url: format }),
    }),
  }),
});

export const { useGetGeolocationDataQuery } = geolocationApi;
