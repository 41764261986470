import { useSelector } from 'react-redux';
import { getUserData, useAddFriendMutation, useLazyRefetchFriendsQuery } from 'store';
import useWebSocket from './useWebSocket';
import { EVENTS } from 'app/constants';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';

export const useAddFriend = () => {
  const { sendMessage } = useWebSocket();
  const [addFriend, { isSuccess, isLoading: isAdding }] = useAddFriendMutation();
  const [refetchFriends, { isLoading: isRefeching }] = useLazyRefetchFriendsQuery();
  const { _id: userId } = useSelector(getUserData);
  const isLoading = isRefeching || isAdding;

  function handleAddFriend(friendId: string) {
    return addFriend({ id: friendId })
      .unwrap()
      .then((res) =>
        refetchFriends()
          .then(() => sendMessage(EVENTS.USERFOLLOWED, { id: userId, targetId: friendId }))
          .then(() => res)
      )
      .catch((ex) => {
        console.error(ex);
        toast.error(<Answer label={'Oops, some error occured:/'} type="declined" />);
      });
  }

  return { addFriend: handleAddFriend, isLoading, isSuccess };
};
