import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from 'store/utils';
import { EAuthMethods, EItemFungibleType, ICustomization, IEvent, IProfile, IUserItem } from 'interface';
import { defaultCustomization, EGroupTag } from 'app/constants';
import { IDailyRewardingResponce, IDailyRewardsResponce, IHistoryFetchResponse, IShapeBody } from './profile.types';
import { OnetimeNotificationTypes } from 'shared/ui/onetime-notifications/types';

export const profileApi = createApi({
  reducerPath: 'profileApi',
  baseQuery: customFetchBase,
  refetchOnFocus: false,
  refetchOnReconnect: true,
  tagTypes: ['User', 'Daily', 'Exp', 'ResultCourses', 'Meditations'],
  endpoints: (builder) => ({
    greetUser: builder.mutation<void, void>({
      query: () => {
        return {
          url: '/users/greet',
          method: 'PATCH',
        };
      },
    }),
    updateInterests: builder.mutation<void, EGroupTag[]>({
      query: (interests) => {
        return {
          url: '/users/interests',
          method: 'PATCH',
          body: { interests },
        };
      },
    }),
    getUserData: builder.query<IProfile, void>({
      query: () => {
        return {
          url: '/users/me',
        };
      },
      transformResponse(data: IProfile): any {
        const { friends } = data;
        return {
          ...data,
          customization: data?.customization ? data?.customization : defaultCustomization,
          friends: friends.map((friend) => ({
            ...friend,
            recipient: {
              ...friend.recipient,
              customization: friend?.recipient?.customization ? friend?.recipient?.customization : defaultCustomization,
            },
          })),
        };
      },
    }),
    visitUserProfile: builder.query<Pick<IProfile, 'exp' | 'nickname' | 'friends' | 'customization'>, string>({
      query: (id: string) => ({
        url: `/users/visit/${id}`,
      }),
    }),
    getMyOnetimerNotifications: builder.query<OnetimeNotificationTypes[], void>({
      query: () => ({ url: '/onetimer/my' }),
    }),
    getMyParts: builder.query<Partial<IProfile>, { props: (keyof IProfile)[] }>({
      query: (params) => ({
        url: 'users/me/partial',
        params,
        method: 'GET',
      }),
      providesTags: ['Exp'],
    }),
    getMyInventory: builder.query<Pick<IProfile, 'exp'>, void>({
      query: () => ({
        url: 'users/me/partial',
        params: { props: ['exp'] },
        method: 'GET',
      }),
      providesTags: ['Exp'],
    }),
    getMyMeditations: builder.query<Pick<IProfile, 'meditations'>, void>({
      query: () => ({
        url: `users/me/partial`,
        params: { props: ['meditations'] },
        method: 'GET',
      }),
      providesTags: ['Meditations'],
    }),
    refetchAfterActivityCompletion: builder.query<Pick<IProfile, 'cooldowns' | 'stats' | 'exp'>, void>({
      query: () => ({
        url: `users/me/partial`,
        params: { props: ['cooldowns', 'stats', 'exp'] },
        method: 'GET',
      }),
    }),
    refetchExpAfterDailyRewarding: builder.query<Pick<IProfile, 'exp' | 'dailis'>, void>({
      query: () => ({
        url: 'users/me/partial',
        params: { props: ['exp', 'dailis'] },
        method: 'GET',
      }),
    }),
    refetchResultCourses: builder.query<Pick<IProfile, 'resultCourses'>, void>({
      query: () => ({
        url: 'users/me/partial',
        params: { props: ['resultCourses'] },
        method: 'GET',
      }),
      providesTags: ['ResultCourses'],
    }),
    refetchFriends: builder.query<Pick<IProfile, 'friends'>, void>({
      query: () => ({
        url: 'users/me/partial',
        params: { props: ['friends'] },
        method: 'GET',
      }),
    }),
    refetchHistory: builder.query<IHistoryFetchResponse, { limit?: number; page?: number }>({
      query: (query) => ({
        url: 'history/me',
        params: query,
        method: 'GET',
      }),
    }),
    readOnetimer: builder.mutation<void, string>({
      query: (_id: string) => {
        return {
          url: `/onetimer/${_id}`,
          method: 'DELETE',
        };
      },
    }),
    readOnetimers: builder.mutation<void, void>({
      query: () => {
        return {
          url: '/onetimer',
          method: 'DELETE',
        };
      },
    }),
    customizeMe: builder.mutation<{ customization: ICustomization; item: IUserItem }, { fungibleType: EItemFungibleType }>({
      query: (data) => {
        return {
          url: '/customization',
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['User'],
    }),
    buyNFT: builder.mutation<any, any>({
      query: (body) => {
        return {
          url: '/payments/nft',
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Exp'],
    }),
    ownCourse: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: '/courses/buy_course',
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['ResultCourses'],
    }),
    readNotifs: builder.mutation<void, void>({
      query: () => {
        return {
          url: '/history/read-notifs',
          method: 'GET',
        };
      },
      invalidatesTags: ['User'],
    }),
    premiumExpirationNotif: builder.mutation<IEvent, void>({
      query: () => {
        return {
          url: '/history/premium-off',
          method: 'POST',
        };
      },
      invalidatesTags: ['User'],
    }),
    sendEmailActivation: builder.mutation<any, { email: string }>({
      query: (data) => {
        return {
          url: '/auth/activate/send',
          method: 'POST',
          body: data,
        };
      },
    }),
    activateUser: builder.query<any, { code: string; email: string }>({
      query: ({ code, email }) => {
        return {
          url: `/auth/activate/${code}`,
          method: 'GET',
          params: {
            email,
          },
        };
      },
    }),

    updateUserDrip: builder.mutation<{ avatar?: string; nickname?: string }, { avatar?: string; nickname?: string }>({
      query: (data) => {
        return {
          url: '/users/drip',
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['User'],
    }),
    addFriend: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: '/friends/add',
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['User'],
    }),
    removeFriend: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: '/friends/reject',
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['User'],
    }),
    declineFriend: builder.mutation<any, any>({
      query: (data) => {
        return {
          url: '/friends/decline',
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['User'],
    }),
    getDailyRewards: builder.query<IDailyRewardsResponce, number | void>({
      query: (collectedCount) => ({ url: `/rewards/daily/?collected=${collectedCount}` }),
    }),
    getIfUserRewardable: builder.query<{ isRewardable: boolean; streak: number }, void>({
      query: () => {
        return {
          // url: '/rewards/if-rewardable/dev', // for dev
          url: '/rewards/if-rewardable',
        };
      },
    }),
    dailyRewardUser: builder.mutation<IDailyRewardingResponce, void>({
      query: () => {
        return {
          // url: '/rewards/daily/dev', // for dev
          url: '/rewards/daily',
          method: 'PUT',
        };
      },
      invalidatesTags: ['Daily'],
    }),
    toggleFavourite: builder.mutation<{ favouriteCourses: IProfile['favouriteCourses'] }, { courseId: string }>({
      query: (data) => {
        return {
          url: '/users/favourite',
          method: 'PUT',
          body: data,
        };
      },
      invalidatesTags: ['ResultCourses'],
    }),
    changeAvatarShape: builder.mutation<IShapeBody, any>({
      query: (data) => {
        return {
          url: '/customization/avatar-shape',
          method: 'PUT',
          body: data,
        };
      },
    }),
    getSignatureMessage: builder.query<{ message: string }, { address: string; type: EAuthMethods }>({
      query: ({ address, type }) => {
        return {
          url: `/auth/signature-message/${type}`,
          params: {
            address,
          },
        };
      },
    }),
  }),
});

export const {
  useLazyGetUserDataQuery,
  useLazyVisitUserProfileQuery,
  useLazyGetMyInventoryQuery,
  useLazyGetMyOnetimerNotificationsQuery,
  useReadOnetimerMutation,
  useReadOnetimersMutation,
  useBuyNFTMutation,
  useReadNotifsMutation,
  usePremiumExpirationNotifMutation,
  useOwnCourseMutation,
  useSendEmailActivationMutation,
  useLazyActivateUserQuery,
  useUpdateUserDripMutation,
  useAddFriendMutation,
  useRemoveFriendMutation,
  useDeclineFriendMutation,
  useLazyGetDailyRewardsQuery,
  useLazyGetIfUserRewardableQuery,
  useDailyRewardUserMutation,
  useToggleFavouriteMutation,
  useCustomizeMeMutation,
  useChangeAvatarShapeMutation,
  useLazyRefetchExpAfterDailyRewardingQuery,
  useLazyRefetchResultCoursesQuery,
  useLazyRefetchFriendsQuery,
  useRefetchHistoryQuery,
  useLazyRefetchHistoryQuery,
  useGreetUserMutation,
  useLazyGetMyMeditationsQuery,
  useLazyRefetchAfterActivityCompletionQuery,
  useLazyGetMyPartsQuery,
  useGetMyPartsQuery,
  useUpdateInterestsMutation,
  useLazyGetSignatureMessageQuery,
} = profileApi;
