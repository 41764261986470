import { BaseQueryFn, FetchArgs, fetchBaseQuery, FetchBaseQueryError } from '@reduxjs/toolkit/dist/query/react';
import { tokenService } from 'services/token.service';
import { authSlice, RootStateType, store } from 'store';
import { Mutex } from 'async-mutex';

// const newBaseURL = 'http://localhost:8000';
const newBaseURL = 'https://courses-parser--py-ruuew.ondigitalocean.app';
const newMutex = new Mutex();

export const arrayParamsSerializer = (data: Record<string, string[] | string>) => {
  const params = new URLSearchParams();

  for (const key in data) {
    if (Array.isArray(data[key])) {
      for (const str of data[key]) {
        params.append(key, str);
      }
    } else {
      params.append(key, data[key].toString());
    }
  }

  return params.toString();
};

const newBaseQuery = fetchBaseQuery({
  baseUrl: newBaseURL,
  prepareHeaders: (headers, { getState }) => {
    const token = tokenService.getAccessToken();
    const lang = (getState() as RootStateType).app.currentLanguage;
    if (!!token) {
      headers.set('authorization', `Bearer ${token}`);
    }
    if (lang) headers.set('Accept-Language', lang);
    return headers;
  },
  paramsSerializer: arrayParamsSerializer,
});

export const courseParserFetchBase: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (args, api, extraOptions) => {
  await newMutex.waitForUnlock();
  let result = await newBaseQuery(args, api, extraOptions);

  if ((result.error?.status as any) === 401) {
    if (!newMutex.isLocked()) {
      const release = await newMutex.acquire();

      try {
        const refreshToken = tokenService.getRefreshToken();
        if (!refreshToken) {
          store.dispatch(authSlice.actions.setIsAuth(false));
          return result;
        }
        const refreshResult: any = await newBaseQuery({ url: 'auth/refresh', method: 'POST', body: { refreshToken } }, api, extraOptions);
        if (refreshResult.data) {
          tokenService.setAccessToken(refreshResult.data.accessToken);
          tokenService.setRefreshToken(refreshResult.data.refreshToken);
          result = await newBaseQuery(args, api, extraOptions);
        } else {
          await newBaseQuery({ url: 'auth/logout', method: 'POST', body: { refreshToken } }, api, extraOptions);
          tokenService.setRemoveTokens();
        }
      } finally {
        release();
      }
    } else {
      await newMutex.waitForUnlock();
      result = await newBaseQuery(args, api, extraOptions);
    }
  }

  return result;
};
