import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICourseFull } from 'interface/courses';
import { authApi } from 'store/auth';
import { IRewardMinimal } from 'store/coursesConstructor';
import { RootStateType } from 'store/store';

type State = {
  quests: ICourseFull[];
  page: number;
  total: number;
  started: boolean;
  totalRewards: IRewardMinimal[];
};

const initialState: State = {
  quests: [],
  page: 1,
  total: 1,
  started: false,
  totalRewards: [],
};

const feedSlice = createSlice({
  name: 'feed',
  initialState,
  reducers: {
    expandFeed: (state, { payload }: PayloadAction<{ quests: ICourseFull[]; total: number; forcePage?: boolean }>) => {
      if (payload.forcePage) {
        state.quests = [...state.quests, ...payload.quests.filter((el) => !state.quests.find((q) => el._id === q._id))];
      } else {
        state.quests = [...state.quests, ...payload.quests];
      }

      state.page++;
      if (payload.total > state.total) {
        state.total = payload.total;
      }
      if (!state.started) {
        state.started = true;
      }
    },
    setFeedRewards: (state, { payload }: PayloadAction<IRewardMinimal[]>) => {
      state.totalRewards = payload;
    },
    cleanupFeed: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.logOut.matchFulfilled, () => {
        return initialState;
      })
      .addMatcher(authApi.endpoints.login.matchFulfilled, () => {
        return initialState;
      })
      .addMatcher(authApi.endpoints.loginMFA.matchFulfilled, () => {
        return initialState;
      });
  },
});

export const getCurrentFeedState = (state: RootStateType) => state.feed;

export const { cleanupFeed, setFeedRewards, expandFeed } = feedSlice.actions;
export default feedSlice.reducer;
