import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'store';

interface IInitialState {
  voice: string | null;
  pitch: number;
  rate: number;
  volume: number;
  text: string;
  isPlaying: boolean;
  isPaused: boolean;
}
const initialState: IInitialState = {
  voice: null,
  pitch: 1,
  rate: 1,
  volume: 1,
  text: '',
  isPlaying: false,
  isPaused: false,
};

export const textToSpeechSlice = createSlice({
  name: 'textToSpeech',
  initialState,
  reducers: {
    setText: (state, action: PayloadAction<any>) => {
      state.text = action.payload;
    },
    setVoice: (state, action: PayloadAction<any>) => {
      state.voice = action.payload;
    },
    setPitch: (state, action: PayloadAction<any>) => {
      state.pitch = action.payload;
    },
    setRate: (state, action: PayloadAction<any>) => {
      state.rate = action.payload;
    },
    setVolume: (state, action: PayloadAction<any>) => {
      state.volume = action.payload;
    },
    setIsPlaying: (state, action: PayloadAction<boolean>) => {
      state.isPlaying = action.payload;
    },
    setIsPaused: (state, action: PayloadAction<boolean>) => {
      state.isPaused = action.payload;
    },
  },
});

export const { setVoice, setRate, setVolume, setPitch, setText, setIsPaused, setIsPlaying } = textToSpeechSlice.actions;

export const getTextToSpeech = (state: RootStateType) => state.textToSpeech;
export default textToSpeechSlice.reducer;
