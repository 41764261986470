import { EVENTS } from 'app/constants';
import { useLazyRefetchFriendsQuery, useRemoveFriendMutation } from 'store';
import useWebSocket from './useWebSocket';

const useRemoveFriend = () => {
  const { sendMessage } = useWebSocket();
  const [removeFriend, { isSuccess, isLoading: isRemoving }] = useRemoveFriendMutation();
  const [refetchFriends, { isLoading: isRefeching }] = useLazyRefetchFriendsQuery();
  const isLoading = isRefeching || isRemoving;

  async function handleRemoveFriend(friendId: string) {
    await removeFriend({ id: friendId }).then(() => {
      refetchFriends();
      sendMessage(EVENTS.UPDATE, { fields: ['friends'], targetId: friendId });
    });
  }

  return { removeFriend: handleRemoveFriend, isLoading, isSuccess };
};

export default useRemoveFriend;
