export type TGrade = 'common' | 'uncommon' | 'rare' | 'epic' | 'legendary' | 'mythical' | 'divine';
export enum EGradeNumber {
  COMMON,
  UNCOMMON,
  RARE,
  EPIC,
  LEGENDARY,
  MYTHICAL,
  DIVINE,
}
