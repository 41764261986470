import { EAuthMethods } from 'interface';
import { useLazyGetMyPartsQuery, useLazyUnattachAuthMethodQuery } from 'store';

type Props = {
  method: EAuthMethods;
};

export const useUnattachUser = ({ method }: Props) => {
  const [unattach, { isFetching: isUnattaching }] = useLazyUnattachAuthMethodQuery();
  const [refetchUserData, { isFetching: isRefetchingUser }] = useLazyGetMyPartsQuery();

  const handleUnattach = async () => {
    return unattach(method)
      .unwrap()
      .then(() => refetchUserData({ props: ['authMethods'] }));
  };
  return {
    handleUnattach,
    isLoading: isUnattaching || isRefetchingUser,
    isUnattaching,
    isRefetchingUser,
  };
};
