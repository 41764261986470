import { useEffect, useState } from 'react';
import { FastAverageColorOptions } from 'fast-average-color';
import getAverageColorByUrl from 'utils/getAverageColorByUrl';
import { averageColorsCache } from 'utils/averageColorsCache';

export interface AverageColorOprionsInterface extends FastAverageColorOptions {
  opacity?: string;
}

const useAverageColorFromImageUrl = (url: string, options?: AverageColorOprionsInterface) => {
  const [color, setColor] = useState('');
  useEffect(() => {
    (async () => {
      if (averageColorsCache.has(url)) {
        setColor(averageColorsCache.get(url).replace(/(rgba\(\d+,\d+,\d+,).*\)/, '$1' + (options && options.opacity ? options?.opacity : 1) + ')'));
        return;
      }
      await getAverageColorByUrl(url, options)
        .then((res) => {
          const color = res.replace(/(rgba\(\d+,\d+,\d+,).*\)/, '$1' + (options && options.opacity ? options?.opacity : 1) + ')');
          averageColorsCache.set(url, res);
          setColor(color);
        })
        .catch(console.error);
    })();
  }, [url, options]);

  return color;
};

export default useAverageColorFromImageUrl;
