import { EEffectsListItemBadgeType } from 'components/library/navbar/UserMenu/panels/effects/badges/types';
import { IExpEffect } from 'interface';
import moment from 'moment';
import { TExpEffectWidgetDataBadge } from 'shared/ui/effect-indicator/types';

export const blankEffect: IExpEffect = {
  createdAt: moment().toDate().toString(),
  updatedAt: moment().toDate().toString(),
  expiredAt: moment().toDate().toString(),
  regenMulti: 1,
  expMulti: 1,
  name: 'Scholar coin',
  reason: '-',
  itemFungibleType: 'scholar-coin',
  id: 0,
};

export const getBadges = (effect: IExpEffect): TExpEffectWidgetDataBadge[] => {
  const badges: TExpEffectWidgetDataBadge[] = [];
  const updatedAt = new Date(effect.updatedAt);
  const expiredAt = new Date(effect.expiredAt);
  if (effect.regenMulti > 1) {
    badges.push({
      type: 'percents',
      effectName: EEffectsListItemBadgeType.ENERGY_RECOVERY,
      percent: Number((effect.regenMulti * 100).toFixed(2)),
      grade: 'uncommon',
    });
  }
  if (effect.expMulti > 1) {
    badges.push({
      type: 'percents',
      effectName: EEffectsListItemBadgeType.EXP,
      percent: Number((effect.expMulti * 100).toFixed(2)),
      grade: 'common',
    });
  }
  if (updatedAt < expiredAt) {
    badges.push({
      type: 'countdown',
      effectName: EEffectsListItemBadgeType.TIMER,
      updatedAt: updatedAt,
      expiredAt: expiredAt,
      grade: 'common',
    });
  }
  if (effect.itemFungibleType === 'energy') {
    badges.push({
      type: 'percents',
      effectName: EEffectsListItemBadgeType.ENERGY,
      grade: 'uncommon',
      percent: 100,
    });
  }
  return badges;
};
