import { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getUserData } from 'store';

type IPremium = {
  isPremium: boolean;
  startedAt: string | null;
  updatedAt: string | null;
  expiresAt: string | null;
};

export const useGetIsPremium = (): IPremium => {
  const {
    exp: {
      limit: { effects },
    },
  } = useSelector(getUserData, shallowEqual);

  const premium = useMemo(() => effects?.find((eff) => eff.name === 'Premium' && new Date(eff.expiredAt).getTime() > Date.now()), [effects]);

  return premium
    ? {
        isPremium: Boolean(premium),
        expiresAt: premium.expiredAt,
        startedAt: premium.createdAt,
        updatedAt: premium.updatedAt,
      }
    : {
        isPremium: Boolean(premium),
        expiresAt: null,
        updatedAt: null,
        startedAt: null,
      };
};
