import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getIsAuth, statisticsApi } from 'store';

export const useStatisticsTimeOnline = () => {
  const $statisticsTimeOnlineTimeoutId = useRef<number | null>();
  const isAuth = useSelector(getIsAuth);
  const [pingOnline] = statisticsApi.usePingOnlineMutation();

  useEffect(() => {
    if (isAuth) {
      $statisticsTimeOnlineTimeoutId.current = window.setInterval(() => {
        pingOnline('');
      }, 5000);
    } else {
      if ($statisticsTimeOnlineTimeoutId.current) {
        window.clearInterval($statisticsTimeOnlineTimeoutId.current);
        $statisticsTimeOnlineTimeoutId.current = null;
      }
    }

    return () => {
      if ($statisticsTimeOnlineTimeoutId.current) {
        window.clearInterval($statisticsTimeOnlineTimeoutId.current);
        $statisticsTimeOnlineTimeoutId.current = null;
      }
    };
  }, [isAuth]);
};
