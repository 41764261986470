import { ITEM_IDS } from 'app/constants';
import { IShopItem } from 'interface';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserData, useLazyFetchShopItemByGoodsIdQuery, useLazyFetchShopItemByIdQuery } from 'store';
import { expTokenItem } from 'utils/items';

export const useGetShopItem = (id: number, type: 'item' | 'goods') => {
  const {
    exp: { items: userItems },
  } = useSelector(getUserData);
  const [getItem, { isFetching: isItemLoading }] = useLazyFetchShopItemByIdQuery();
  const [getGoods, { isFetching: isGoodsLoading }] = useLazyFetchShopItemByGoodsIdQuery();
  const [item, setItem] = useState<IShopItem | undefined>(undefined);
  const isLoading = isItemLoading || isGoodsLoading;

  useEffect(() => {
    if (id === ITEM_IDS.EXP_TOKEN) {
      setItem(expTokenItem);
    } else {
      (async () => {
        if (type === 'goods') {
          const item = await getGoods(id)
            .unwrap()
            .then((res) => structuredClone(res));
          item.userBalance = userItems.find((i) => i.item.id === item.item.id)?.quantity ?? 0;
          setItem(item);
        } else {
          const shopItem = await getItem(id)
            .unwrap()
            .then((res) => structuredClone(res))
            .catch(console.log);

          const item = structuredClone(userItems.find((i) => i.item.id === id)) as IShopItem;
          if (!item) {
            if (!shopItem) return;
            setItem({ ...shopItem, userBalance: 0 });
            return;
          }
          item.userBalance = item?.quantity ?? 0;
          item.quantity = 0;
          if (shopItem) {
            item.id = shopItem.id;
            item.price = shopItem.price;
            item.quantity = shopItem?.quantity;
          }

          setItem(item);
        }
      })();
    }
  }, [getItem, getGoods, id, type, userItems]);

  return { item, isLoading };
};
