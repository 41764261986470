import { useActivateItemMutation } from 'store';

export const useActivateItem = () => {
  const [activateItem, { isLoading: isActivating }] = useActivateItemMutation();

  const activate = async (id: number) => {
    return activateItem(id).unwrap();
  };

  return { activate, isLoading: isActivating };
};
