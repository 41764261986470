import { useEffect, useState } from 'react';

export const useTimer = (initialMinute: number, initialSeconds: number) => {
  const [seconds, setSeconds] = useState<number>(initialSeconds);
  const [minutes, setMinutes] = useState<number>(initialMinute);
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  const startAgainHandler = () => {
    setMinutes(initialMinute);
    setSeconds(initialSeconds);
  };

  if (minutes === 0 && seconds === 0) {
    return { minutes: 0, seconds: 0, startAgainHandler: startAgainHandler };
  }

  return { minutes: minutes, seconds: seconds < 10 ? `0${seconds}` : seconds };
};
