export enum EGroupTag {
  AIAutomation = 'AI & Automation',
  BitcoinCryptocurrency = 'bitcoin & cryptocurrency',
  SocialMedia = 'social media',
  Web3 = 'web3',
  Gaming = 'gaming',
  DecentralisedFinance = 'decentralised finance',
  WebDevelopment = 'web development',
  DataScience = 'data science',
  Programming = 'programming',
  BlockchainProgramming = 'blockchain programming',
  Originals = 'originals',
}
