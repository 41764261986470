import { useEffect, useState } from 'react';

export const useGetContainerSize = (node: HTMLDivElement | null) => {
  const [size, setSize] = useState({ width: 1, height: 1 }); // to prevent division by zero
  useEffect(() => {
    if (!node) {
      return;
    }
    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame(() => {
        if (!Array.isArray(entries) || !entries.length || !node) {
          return;
        }
        setSize({
          width: node.getBoundingClientRect().width || 0,
          height: node.getBoundingClientRect().height || 0,
        });
      });
    });
    resizeObserver.observe(node);
    return () => resizeObserver.disconnect();
  }, [node]);

  return size;
};
