import { useMemo } from 'react';
import { ICourse } from 'interface/courses';
import { useGetUserData } from './useGetUserData';

const useGetCourseProgressChapters = ({ course }: { course: ICourse }) => {
  const { resultCourses } = useGetUserData();

  const resultCourse = useMemo(() => resultCourses?.find((el) => el.courseId === course._id), [course._id]);
  const singleChapter = useMemo(() => (course.chapters.length === 1 ? course.chapters[0] : null), [course.chapters]);
  const { total, completed } = useMemo(() => {
    if (singleChapter) {
      const resChapter = resultCourse && resultCourse.resultChapters.find((el) => el.chapterId === singleChapter._id);
      return singleChapter.lessons.reduce(
        (acc, lesson) => {
          acc.total += 1;
          if (!!resChapter && !!resChapter.resultLessons.find((el) => el.lessonId === lesson._id && el.status === 'finished')) {
            acc.completed += 1;
          }
          return acc;
        },
        { total: 0, completed: 0 }
      );
    }
    return course.chapters.reduce(
      (acc, chapter) => {
        acc.total += 1;
        if (!!resultCourse && !!resultCourse.resultChapters.find((el) => el.chapterId === chapter._id && el.status === 'finished')) {
          acc.completed += 1;
        }
        return acc;
      },
      { total: 0, completed: 0 }
    );
  }, [resultCourse, course, singleChapter]);

  return { total, completed };
};

export default useGetCourseProgressChapters;
