import { CSSProperties, DetailedHTMLProps, FC, ImgHTMLAttributes } from 'react';
// import { motion } from 'framer-motion';
// import { useSelector } from 'react-redux';
// import { getAppSettings } from 'store';

interface Props extends DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
  style?: CSSProperties;
  src: string;
  alt: string;
  className?: string;
  width?: string | number;
  height?: string | number;
}

export const LazyImage: FC<Props> = ({ src, alt, className = '', width = 'auto', height = 'auto', style = {} }) => {
  // const [isVisible, setIsVisible] = useState(false);
  // const {
  //   logos: { small },
  // } = useSelector(getAppSettings);
  // const [imageSrc, setImageSrc] = useState(small);
  // const imageRef = useRef<HTMLImageElement>(null);

  // const handleIntersection = (entries: IntersectionObserverEntry[], observer: IntersectionObserver) => {
  //   entries.forEach((entry) => {
  //     if (entry.isIntersecting) {
  //       setIsVisible(true);
  //       observer.unobserve(entry.target);
  //     }
  //   });
  // };

  // useEffect(() => {
  //   // Create an Intersection Observer with a callback function
  //   const observer = new IntersectionObserver(handleIntersection, {
  //     root: null,
  //     rootMargin: '0px',
  //     threshold: 0.1,
  //   });
  //   // Observe the image element when it exists
  //   if (imageRef.current) {
  //     observer.observe(imageRef.current);
  //   }
  //   // Clean up by unobserving the image element when component is unmounted
  //   return () => {
  //     if (imageRef.current) {
  //       observer.unobserve(imageRef.current);
  //     }
  //   };
  // }, []);

  // useEffect(() => {
  //   if (isVisible) {
  //     setImageSrc(src);
  //   }
  // }, [isVisible, src]);

  // return (
  //   <motion.img
  //     transition={{ ease: 'easeOut', duration: 0.3 }}
  //     animate={{
  //       opacity: isVisible ? 1 : 0,
  //     }}
  //     initial={{ opacity: 0 }}
  //     style={style}
  //     className={className}
  //     ref={imageRef}
  //     src={imageSrc}
  //     alt={alt}
  //     width={width}
  //     height={height}
  //     loading={'lazy'}
  //   />
  // );

  return <img style={style} className={className} src={src} alt={alt} width={width} height={height} loading={'lazy'} />;
};
