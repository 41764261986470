export enum EVENTS {
  ITEMACTIVATED = 'itemActivated',
  ITEMRECEIVED = 'itemReceived',
  ITEMDAILYREWARDRECEIVED = 'itemDailyRewardReceived',
  ITEMPURCHASED = 'itemPurchased',
  NFTRECEIVED = 'nftReceived',
  NFTPURCHASED = 'nftPurchased',
  COURSEPURCHASED = 'coursePurchased',
  COURSECREATED = 'courseCreated',
  COURSECOMPLETED = 'courseCompleted',
  LESSONCOMPLETED = 'lessonCompleted',
  CHAPTERCOMPLETED = 'chapterCompleted',
  USERFOLLOWED = 'userFollowed',
  USERFOLLOWING = 'userFollowing',
  USERFRIENDED = 'userFriended',
  USERUNFRIENDED = 'userUnfriended',
  USERCHALLENGED = 'userChallenged',
  USERWON = 'userWon',
  USERLOST = 'userLost',
  USERREWARDED = 'userRewarded',
  UPDATE = 'update',
  PREMIUMACTIVATED = 'premiumActivated',
  PREMIUMDEACTIVATED = 'premiumDeactivated',
  DISCOUNTCODEACTIVATED = 'discountCodeActivated',
  NFTTRANSFERED = 'NFTTransfered',
}

export enum SUBJECTS {
  ITEMS = 'items',
  NFTS = 'nfts',
  COURSES = 'courses',
  BATTLES = 'battles',
  FRIENDS = 'friends',
  PREMIUM = 'premium',
}
