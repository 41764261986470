import { createApi } from '@reduxjs/toolkit/query/react';
import { customFetchBase } from 'store/utils';

export const searchApi = createApi({
  reducerPath: 'searchApi',
  baseQuery: customFetchBase,
  tagTypes: ['User'],
  endpoints: (builder) => ({
    search: builder.query<any, string>({
      query: (input) => {
        return {
          url: `/search/${input}`,
        };
      },
      providesTags: ['User'],
    }),
  }),
});

export const { useLazySearchQuery } = searchApi;
