import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootStateType } from 'store/store';

interface PartnerState {
  partnerID: string | null;
  partnerToken: string | null;
  theme: 'light' | 'dark';
}

const initialState: PartnerState = {
  partnerID: null,
  partnerToken: null,
  theme: 'dark',
};

const partnerSlice = createSlice({
  name: 'partner',
  initialState,
  reducers: {
    setPartnerData: (state, action: PayloadAction<PartnerState>) => {
      state.partnerID = action.payload.partnerID;
      state.partnerToken = action.payload.partnerToken;
      state.theme = action.payload.theme;
    },
    resetPartnerData: (state) => {
      state.partnerID = null;
      state.partnerToken = null;
      state.theme = 'dark';
    },
  },
});

const partnerId = (state: RootStateType) => state.partner.partnerID;
export const { setPartnerData, resetPartnerData } = partnerSlice.actions;
export const getPartnerId = createSelector(partnerId, (partner) => partner);
export default partnerSlice.reducer;
