import { useSelector } from 'react-redux';
import { getPageIsLoaded, getPartnerId, setPageIsLoaded } from 'store';
import { createPortal } from 'react-dom';
import { useCallback, useEffect } from 'react';
import { dispatch } from 'hooks';
import PartnerLoader from '../PartnerLoader';
import { PageLoader } from '../PageLoader';

export const GlobalLoader = ({ isLoading: isForcedLoading = true, onLoad }: { isLoading?: boolean; onLoad?: () => void }) => {
  const pageIsLoaded = useSelector(getPageIsLoaded);
  const partnerId = useSelector(getPartnerId);

  useEffect(() => {
    let timeout: any;
    if (!pageIsLoaded) {
      timeout = setTimeout(() => {
        return dispatch(setPageIsLoaded(true));
      }, 10000);
    }

    return () => {
      clearInterval(timeout);
    };
  }, [pageIsLoaded]);

  const handleAnimateIsExit = useCallback(() => {
    if (onLoad && pageIsLoaded) {
      onLoad();
    }
  }, [onLoad, pageIsLoaded]);

  const isLoading = isForcedLoading || !pageIsLoaded;

  if (!!partnerId) {
    return createPortal(<PartnerLoader isFixed isLoading={isLoading} />, document.getElementById('loading-portal') as HTMLDivElement);
  }

  return createPortal(
    <PageLoader isFixed isLoading={isLoading} setAnimateIsExit={handleAnimateIsExit} />,
    document.getElementById('loading-portal') as HTMLDivElement
  );
};
